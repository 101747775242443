import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 100%;
  .header {
    width: calc(100% - 20px);
    max-width: 1180px;
    height: 60px;
    position: fixed;
    z-index: 2;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-gap: 10px;
    padding: 0 10px;
    .button__back {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 10px auto auto;
      background: rgba(127, 127, 127, 0.3);
      border: 0;
      outline: none;
      border-radius: 50%;
      ${(props) => (props.theme.direction === "RTL" ? "order:3;" : "order:1;")}
      svg {
        ${(props) =>
          props.theme.direction === "RTL" && "transform: rotate(180deg);"}
      }
    }
    .color {
      color: ${(props) => props.theme.paletteColors.primary};
    }
    i {
      ${(props) => (props.theme.direction === "RTL" ? "order:1;" : "order:3;")}
    }
  }
  .products-container__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px 10px;
    margin: 0 10px;
    padding-top: 75px;
  }
  .sarchArea {
    width: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    order: 2;
    svg {
      width: 18px;
      position: absolute;
      top: 5px;
      ${(props) =>
        props.theme.direction === "RTL" ? "right: 9px" : "left: 9px"};
      z-index: 9;
      opacity: 0.3;
    }
    .search-input {
      width: calc(100% - 35px);
      height: 35px;
      padding: 0;
      padding: ${(props) =>
        props.theme.direction === "RTL" ? "0 35px 0 0" : "0 0 0 35px"};
      font-family: "Cairo";
      color: #ffffff;
      font-size: 15px;
      border: 0;
      border-radius: 8px;
      background-color: #ffffff42;
      text-align: ${(props) =>
        props.theme.direction === "RTL" ? "right" : "left"};
      transition: all 0.2s ease-in-out;
      outline: none;
      :focus {
        position: relative;
        z-index: 5;
        border-bottom: 1px solid #eeeef1;
        color: #3a3a46;
        background-color: #ffffff;
      }
    }
    .autocomplete {
      position: relative;
      z-index: 5;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 1px solid #eeeef1;
      background-color: #ffffff;
    }
  }
  .suggestion-list {
    width: 100%;
    position: absolute;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2);
    ul {
      margin: 10px 0;
      padding: 0;
      li {
        padding: 3px 15px;
        font-size: 14px;
        list-style: none;
        font-family: Cairo;
        :hover {
          background-color: #eeeef1;
        }
      }
    }
  }
  .search--empty {
    padding: 10px;
    font-size: 18px;
    text-align: center;
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
    color: ${(props) => props.theme.paletteColors.primary || "#ffffff"};
  }
  @media (min-width: 930px) {
    .products-container__grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;
