export const translate = (language: string, index: number) => {
  switch (language) {
    case "FR":
      return dictionary[index].FR;
    case "AR":
      return dictionary[index].AR;
    case "EN":
      return dictionary[index].EN;
    case "DE":
      return dictionary[index].DE;
    case "IT":
      return dictionary[index].IT;
    case "ES":
      return dictionary[index].SP;
    case "PT":
      return dictionary[index].PT;
    case "TR":
      return dictionary[index].TR;
    case "ZH":
      return dictionary[index].ZH;
    case "JP":
      return dictionary[index].JP;
    default:
      return dictionary[index].FR;
  }
};

// to add new term to translate
// add a new object with the following form:
// {FR:"",EN:"",AR:"",DE:"",IT:"",SP:"",PT:"",TR:"",ZH:"",JP:""}
//  12

const dictionary = [
  {
    FR: "Entrer",
    EN: "Enter",
    AR: "دخول",
    DE: "Eingeben",
    IT: "Entrare",
    SP: "Entrar",
    PT: "Entrar",
    TR: "Girmek",
    ZH: "进入",
    JP: "入る",
  }, // 0
  {
    FR: "Choisir votre Langue",
    EN: "Choose your language",
    AR: "إختر اللغة",
    DE: "Wähle deine Sprache",
    IT: "Scegli la tua lingua",
    SP: "Elige tu idioma",
    PT: "Escolha seu idioma",
    TR: "Dilinizi seçin",
    ZH: "选择你的语言",
    JP: "言語を選んでください",
  }, // 1
  {
    FR: "Savoir plus",
    EN: "More",
    AR: "المزيد",
    DE: "Mehr",
    IT: "Di Più",
    SP: "Más",
    PT: "Mais",
    TR: "Daha",
    ZH: "更多",
    JP: "もっと",
  }, // 2
  {
    FR: "Annuler",
    EN: "Cancel",
    AR: "إلغاء",
    DE: "Abbrechen",
    IT: "Annulla",
    SP: "Cancelar",
    PT: "Cancelar",
    TR: "İptal etmek",
    ZH: "取消",
    JP: "キャンセル",
  }, // 3
  {
    FR: "Actualiser",
    EN: "Refresh",
    AR: "تحديث",
    DE: "Aktualisieren",
    IT: "Ricaricare",
    SP: "Actualizar",
    PT: "Atualizar",
    TR: "Yenile",
    ZH: "刷新",
    JP: "リフレッシュ",
  }, // 4
  {
    FR: "Oups! Une erreur est survenu, veuillez actualiser la page",
    EN: "Oups! There must have been an error, please refresh the page",
    AR: "عفوًا! حدث خطأ ، يرجى تحديث الصفحة",
    DE: "Ups! Es ist ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite",
    IT: "Oups! Deve essersi verificato un errore, aggiorna la pagina",
    SP: "Oups! Debe haber habido un error, por favor actualice la página",
    PT: "Oups! Deve ter havido um erro, atualize a página",
    TR: "Oups! Bir hata olmuş olmalı, lütfen sayfayı yenileyin",
    ZH: "哎呀！肯定有错误，请刷新页面",
    JP:
      "おーっ！エラーが発生している可能性があります。ページを更新してください",
  }, // 5
  {
    FR: "Ajouter à mon panier",
    EN: "Add to my cart",
    AR: "أضف إلى سلة التسوق",
    DE: "In meinen Warenkorb legen",
    IT: "Aggiungi al mio carrello",
    SP: "Añadir a mi carrito",
    PT: "Adicionar ao carrinho",
    TR: "Sepetime ekle",
    ZH: "添加到我的购物车",
    JP: "カートに追加",
  }, // 6
  {
    FR: "Retirer du panier",
    EN: "Remove from my cart",
    AR: "أزله من سلة التسوق",
    DE: "Aus dem Korb nehmen",
    IT: "Rimuovere dal carrello",
    SP: "Eliminar de mi carrito",
    PT: "Remover do meu carrinho",
    TR: "Sepetimden kaldır",
    ZH: "从我的购物车中删除",
    JP: "カートから削除",
  }, // 7
  {
    FR: "Lien copié",
    EN: "Link copied",
    AR: "تم نسخ الرابط",
    DE: "Link kopiert",
    IT: "Link copiato",
    SP: "Enlace copiado",
    PT: "Link copiado",
    TR: "Bağlantı kopyalandı",
    ZH: "链接已复制",
    JP: "リンクをコピーしました",
  }, // 8
  {
    FR: "Produit ajouté",
    EN: "Product added",
    AR: "تمت إضافة المنتج",
    DE: "Produkt hinzugefügt",
    IT: "Prodotto aggiunto",
    SP: "Producto agregado",
    PT: "Produto adicionado",
    TR: "Ürün eklendi",
    ZH: "已添加产品",
    JP: "追加された製品",
  }, // 9
  {
    FR: "Produit retiré",
    EN: "Product removed",
    AR: "تمت إزالة المنتج",
    DE: "Produkt entfernt",
    IT: "Prodotto rimosso",
    SP: "Producto agregado",
    PT: "Produto removido",
    TR: "Ürün kaldırıldı",
    ZH: "产品已移除",
    JP: "削除された製品",
  }, // 10
  {
    FR: "Votre panier est vide",
    EN: "Your cart is empty",
    AR: "سلة التسوق فارغة",
    DE: "Ihr Warenkorb ist leer",
    IT: "il tuo carrello è vuoto",
    SP: "Tu carrito esta vacío",
    PT: "Seu carrinho está vazio",
    TR: "Sepetiniz boş",
    ZH: "您的购物车是空的",
    JP: "あなたのカートは空です",
  }, // 11
  {
    FR: "Ajoutez des produits et passez votre commande en ligne.",
    EN: "Add products and place your order online",
    AR: "أضف منتجات واحصل على طلبك عبر الانترنت",
    DE: "Fügen Sie Produkte hinzu und bestellen Sie online",
    IT: "Aggiungi prodotti e invia il tuo ordine online",
    SP: "Agregue productos y haga su pedido en línea",
    PT: "Adicione produtos e faça seu pedido on-line",
    TR: "Ürün ekleyin ve siparişinizi çevrimiçi verin",
    ZH: "添加产品并在线下订单",
    JP: "製品を追加してオンラインで注文する",
  }, // 12
  {
    FR: "Mon panier",
    EN: "My cart",
    AR: "سلةالتسوق",
    DE: "Mein Warenkorb",
    IT: "La mia carta",
    SP: "Mi carrito",
    PT: "Meu carrinho",
    TR: "Sepetim",
    ZH: "我的车",
    JP: "私のカート",
  }, // 13
  {
    FR: "Vos informations",
    EN: "Your informations",
    AR: "معلوماتكم",
    DE: "Ihre Informationen",
    IT: "Le tue informazioni",
    SP: "Su informacion",
    PT: "Sua informação",
    TR: "Bilgileriniz",
    ZH: "您的资料",
    JP: "あなたの情報",
  }, // 14
  {
    FR: "Récapitulatif",
    EN: "Summary",
    AR: "ملخص",
    DE: "Zusammenfassung",
    IT: "Sommario",
    SP: "Resumen",
    PT: "Sumário",
    TR: "özet",
    ZH: "摘要",
    JP: "概要",
  }, // 15
  {
    FR: "Suivant",
    EN: "Next",
    AR: "التالي",
    DE: "Nächster",
    IT: "Il prossimo",
    SP: "próximo",
    PT: "Próximo",
    TR: "Sonraki",
    ZH: "下一个",
    JP: "次",
  }, // 16
  {
    FR: "Retour",
    EN: "Back",
    AR: "إلى الوراء",
    DE: "Zurück",
    IT: "Indietro",
    SP: "atrás",
    PT: "Costas",
    TR: "Geri",
    ZH: "背部",
    JP: "バック",
  }, // 17
  {
    FR: "Total",
    EN: "Total",
    AR: "المجموع",
    DE: "Total",
    IT: "Total",
    SP: "Total",
    PT: "Total",
    TR: "Toplam",
    ZH: "总",
    JP: "合計",
  }, // 18
  {
    FR: "Frais de livraison",
    EN: "Delivery cost",
    AR: "تكاليف التوصيل",
    DE: "Versandkosten",
    IT: "Costo di consegna",
    SP: "Coste de envío",
    PT: "Custo de entrega",
    TR: "Teslimat maliyeti",
    ZH: "运输成本",
    JP: "配送費用",
  }, // 19
  {
    FR: "Gratuit",
    EN: "Free",
    AR: "مجاني",
    DE: "Kostenlos",
    IT: "Gratuito",
    SP: "Gratis",
    PT: "Livre",
    TR: "Bedava",
    ZH: "自由",
    JP: "自由",
  }, // 20
  {
    FR: "Renseignez vos informations",
    EN: "Fill in your information",
    AR: "املأ معلومات الخاصة بك",
    DE: "Geben Sie Ihre Lieferinformationen ein",
    IT: "Inserisci le informazioni di consegna",
    SP: "Complete su información de entrega",
    PT: "Preencha suas informações de entrega",
    TR: "Teslimat bilgilerinizi doldurun",
    ZH: "填写您的送货信息",
    JP: "配送情報を入力してください",
  }, // 21
  {
    FR: "Prénom",
    EN: "Name",
    AR: "الاسم",
    DE: "Name",
    IT: "Nome",
    SP: "Nombre",
    PT: "Nome",
    TR: "Isim",
    ZH: "名称",
    JP: "名前",
  }, // 22
  {
    FR: "Nom",
    EN: "Last name",
    AR: "اللقب",
    DE: "Nachname",
    IT: "Cognome",
    SP: "Apellido",
    PT: "Sobrenome",
    TR: "Soyadı",
    ZH: "姓氏",
    JP: "姓",
  }, // 23
  {
    FR: "Numéro de téléphone",
    EN: "Phone number",
    AR: "رقم الهاتف",
    DE: "Telefonnummer",
    IT: "Numero di telefono",
    SP: "Número de teléfono",
    PT: "Número de telefone",
    TR: "Telefon numaras",
    ZH: "电话号码",
    JP: "電話番号",
  }, // 24
  {
    FR: "Email",
    EN: "Email",
    AR: "البريد الإلكتروني",
    DE: "Email",
    IT: "Email",
    SP: "Email",
    PT: "O email",
    TR: "E-posta",
    ZH: "电子邮件",
    JP: "Eメール",
  }, // 25
  {
    FR: "Note",
    EN: "Note",
    AR: "ملاحظة",
    DE: "Hinweis",
    IT: "Nota",
    SP: "Nota",
    PT: "Nota",
    TR: "Not",
    ZH: "注意",
    JP: "注意",
  }, // 26
  {
    FR: "Ce champ est requis",
    EN: "This field is required",
    AR: "هذه الخانة مطلوبه",
    DE: "Dieses Feld wird benötigt",
    IT: "Questo campo è obbligatorio",
    SP: "este campo es requerido",
    PT: "Este campo é obrigatório",
    TR: "Bu alan gereklidir",
    ZH: "这是必填栏",
    JP: "この項目は必須です",
  }, // 27
  {
    FR: "Informations",
    EN: "information",
    AR: "معلومات ",
    DE: "Lieferinformationen",
    IT: "Informazioni di consegna",
    SP: "Información de entrega",
    PT: "Informação de entrega",
    TR: "Teslimat Bilgisi",
    ZH: "配送信息",
    JP: "配送に関する情報",
  }, // 28
  {
    FR: "Ma commande",
    EN: "Delivery information",
    AR: "طلباتي",
    DE: "Meine Bestellung",
    IT: "Il mio ordine",
    SP: "Mi pedido",
    PT: "Meu pedido",
    TR: "Benim siparişim",
    ZH: "我的订单",
    JP: "私の注文",
  }, // 29
  {
    FR: "Nom et prènom",
    EN: "First name and last name",
    AR: "الاسم و اللقب",
    DE: "Vorname und Nachname",
    IT: "Nome e cognome",
    SP: "Nombre y apellido",
    PT: "Nome e sobrenome",
    TR: "Isim soyisim",
    ZH: "名和姓",
    JP: "姓と名",
  }, // 30
  {
    FR: "Vider le panier",
    EN: "Empty the cart",
    AR: "أفرغ السلة",
    DE: "Leere",
    IT: "Carrello vuoto",
    SP: "Carro vacio",
    PT: "Carrinho vazio",
    TR: "Boş araba",
    ZH: "空车",
    JP: "空のカート",
  }, // 31
  {
    FR: "Confirmer",
    EN: "Confirm",
    AR: "وافق",
    DE: "Bestätigen",
    IT: "Confermare",
    SP: "Confirmar",
    PT: "confirme",
    TR: "Onaylamak",
    ZH: "确认",
    JP: "確認",
  }, // 32
  {
    FR: "Télécharger le ticket",
    EN: "Download ticket",
    AR: "نزل التذكرة",
    DE: "Ticket herunterladen",
    IT: "Scarica il biglietto",
    SP: "Descargar boleto",
    PT: "Download do ticket",
    TR: "Bilet indir",
    ZH: "下载门票",
    JP: "チケットをダウンロード",
  }, // 33
  {
    FR: "Votre commande est en cours de traitement",
    EN: "Your order is being processed",
    AR: "سيتم تجهيز طلبك",
    DE: "Ihre Bestellung wird bearbeitet",
    IT: "Il vostro ordine è in fase di elaborazione",
    SP: "Tu pedido está siendo procesado",
    PT: "Seu pedido está sendo processado",
    TR: "Siparişiniz işleniyor",
    ZH: "您的订单正在处理中",
    JP: "ご注文は処理されています",
  }, // 34
  {
    FR: "Utiliser le code ci-dessous en cas de besoin",
    EN: "Use this code below when needed",
    AR: "استخدم هذا الرمز أدناه عند الحاجة",
    DE: "Verwenden Sie diesen Code bei Bedarf unten",
    IT: "Utilizzare questo codice di seguito quando necessario",
    SP: "Use este código a continuación cuando sea necesario",
    PT: "Use este código abaixo quando necessário",
    TR: "Gerektiğinde aşağıdaki bu kodu kullanın",
    ZH: "需要时在下方使用此代码",
    JP: "必要に応じて、以下のこのコードを使用してください",
  }, // 35
  {
    FR: "Quantité ",
    EN: "Quantity ",
    AR: "الكمية",
    DE: "Menge ",
    IT: "Quantità ",
    SP: "Cantidad ",
    PT: "Quantidade ",
    TR: "Miktar ",
    ZH: "数量在 ",
    JP: "数量 ",
  }, // 36
  {
    FR: "Prix unitaire:",
    EN: "Unit price:",
    AR: ":سعر الوحدة",
    DE: "Stückpreis:",
    IT: "Prezzo unitario:",
    SP: "Precio unitario:",
    PT: "Preço unitário:",
    TR: "Birim fiyatı:",
    ZH: "单价：",
    JP: "単価：",
  }, // 37
  {
    FR: "Quantité modifié",
    EN: "Quantity updated",
    AR: "تم تحديث الكمية",
    DE: "Menge aktualisiert",
    IT: "Quantità aggiornata",
    SP: "Cantidad actualizada",
    PT: "Quantidade atualizada",
    TR: "Miktar güncellendi",
    ZH: "数量已更新",
    JP: "更新された数量",
  }, // 38
  {
    FR: "Chercher",
    EN: "Search",
    AR: "ابحث",
    DE: "Suche",
    IT: "Ricerca",
    SP: "Buscar",
    PT: "Procurar",
    TR: "Arama",
    ZH: "搜索",
    JP: "探す",
  }, // 39
  {
    FR: "Choisir vos accompagnements",
    EN: "Choose a supplement",
    AR: "اختر المكمل",
    DE: "Choose a supplement",
    IT: "Choose a supplement",
    SP: "Choose a supplement",
    PT: "Choose a supplement",
    TR: "Choose a supplement",
    ZH: "Choose a supplement",
    JP: "Choose a supplement",
  }, // 40
  {
    FR: "Maximum ",
    EN: "Maximum ",
    AR: " تتطلب هذه الزيادة بحد أقصاه",
    DE: "Maximum ",
    IT: "Maximum ",
    SP: "Maximum ",
    PT: "Maximum ",
    TR: "Maximum ",
    ZH: "Maximum ",
    JP: "Maximum ",
  }, // 41
  {
    FR: "choix ",
    EN: "choices ",
    AR: "خيارات",
    DE: "choices ",
    IT: "choices ",
    SP: "choices ",
    PT: "choices ",
    TR: "choices ",
    ZH: "choices ",
    JP: "choices ",
  }, // 42
  {
    FR: "Minimum ",
    EN: "Minimum ",
    AR: "يتطلب هذا الإضافي حدًا أدنى من",
    DE: "Minimum ",
    IT: "Minimum ",
    SP: "Minimum ",
    PT: "Minimum ",
    TR: "Minimum ",
    ZH: "Minimum ",
    JP: "Minimum ",
  }, // 43
  {
    FR: "Adresse",
    EN: "Address",
    AR: "عنوان",
    DE: "Address",
    IT: "Address",
    SP: "Address",
    PT: "Address",
    TR: "Address",
    ZH: "Address",
    JP: "Address",
  }, // 44
  {
    FR: "Fermer",
    EN: "Close",
    AR: "أغلق",
    DE: "Close",
    IT: "Close",
    SP: "Close",
    PT: "Close",
    TR: "Close",
    ZH: "Close",
    JP: "Close",
  }, // 45
  {
    FR: "Sélectionner vôtre moyen de paiement",
    EN: "Payment Method",
    AR: "طريقة الدفع",
    DE: "Payment Method",
    IT: "Payment Method",
    SP: "Payment Method",
    PT: "Payment Method",
    TR: "Payment Method",
    ZH: "Payment Method",
    JP: "Payment Method",
  }, // 46
  {
    FR: "Paiement sur place",
    EN: "Click and collect",
    AR: "الدفع عند الاستلام",
    DE: "Click and collect",
    IT: "Click and collect",
    SP: "Click and collect",
    PT: "Click and collect",
    TR: "Click and collect",
    ZH: "Click and collect",
    JP: "Click and collect",
  }, // 47
  {
    FR: "Paiement en ligne CB",
    EN: "Payment by credit card",
    AR: "الدفع ببطاقة الائتمان",
    DE: "Payment by credit card",
    IT: "Payment by credit card",
    SP: "Payment by credit card",
    PT: "Payment by credit card",
    TR: "Payment by credit card",
    ZH: "Payment by credit card",
    JP: "Payment by credit card",
  }, // 48
  {
    FR: "Vous devez accepter les conditions générales de vente de MenuMe.",
    EN: "You must accept Menume's general Term and conditions.",
    AR: "يجب قبول الشروط العامة للبيع",
    DE: "You must accept Menume's general Term and conditions.",
    IT: "You must accept Menume's general Term and conditions.",
    SP: "You must accept Menume's general Term and conditions.",
    PT: "You must accept Menume's general Term and conditions.",
    TR: "You must accept Menume's general Term and conditions.",
    ZH: "You must accept Menume's general Term and conditions.",
    JP: "You must accept Menume's general Term and conditions.",
  }, // 49
  {
    FR: "Non disponible",
    EN: "Not available",
    AR: "غير متاح",
    DE: "Not available",
    IT: "Not available",
    SP: "Not available",
    PT: "Not available",
    TR: "Not available",
    ZH: "Not available",
    JP: "Not available",
  }, // 50
  {
    FR: "Paiement en ligne avec SKIPCASH",
    EN: "Payment using skipcash",
    AR: "الدفع ببطاقة الائتمان",
    DE: "Payment using skipcash",
    IT: "Payment using skipcash",
    SP: "Payment using skipcash",
    PT: "Payment using skipcash",
    TR: "Payment using skipcash",
    ZH: "Payment using skipcash",
    JP: "Payment using skipcash",
  }, // 51
  {
    FR: "Paiement effectué avec succée",
    EN: "Payment succeeded",
    AR: "الدفع ببطاقة الائتمان",
    DE: "Payment succeeded",
    IT: "Payment succeeded",
    SP: "Payment succeeded",
    PT: "Payment succeeded",
    TR: "Payment succeeded",
    ZH: "Payment succeeded",
    JP: "Payment succeeded",
  }, // 52
  {
    FR: "Paiement echoué",
    EN: "Payment failed",
    AR: "الدفع ببطاقة الائتمان",
    DE: "Payment failed",
    IT: "Payment failed",
    SP: "Payment failed",
    PT: "Payment failed",
    TR: "Payment failed",
    ZH: "Payment failed",
    JP: "Payment failed",
  }, // 53
  {
    FR: "Rester avec nous !",
    EN: "Stay with us !",
    AR: "ابقى معنا!",
    DE: "Stay with us !",
    IT: "Stay with us !",
    SP: "Stay with us !",
    PT: "Stay with us !",
    TR: "Stay with us !",
    ZH: "Stay with us !",
    JP: "Stay with us !",
  }, // 54
  {
    FR: "Temps de preparation:",
    EN: "Prep time:",
    AR: ":وقت التحضير",
    DE: "Vorbereitungszeit:",
    IT: "tempo di preparazione:",
    SP: "tiempo de preparación:",
    PT: "tempo de preparação:",
    TR: "hazırlanma zamanı:",
    ZH: "准备时间：",
    JP: "準備時間：",
  }, // 55
  {
    FR: "non précisé",
    EN: "not specified",
    AR: ":غير محدد",
    DE: "unbestimmt",
    IT: "non specificato",
    SP: "no especificado",
    PT: "não especificado",
    TR: "belirtilmemiş",
    ZH: "未指定",
    JP: "指定されていない",
  }, // 56
];

export default dictionary;
