import gql from "graphql-tag";

export const CREATMENUNAVIGATION = gql`
  mutation CreateMenuNavigation($input: CreateMenuNavigationInput!) {
    createMenuNavigation(input: $input) {
      success
    }
  }
`;

export const CREATECATEGORYNAVIGATION = gql`
  mutation CreateCategoryNavigation($input: CreateCategoryNavigationInput!) {
    createCategoryNavigation(input: $input) {
      success
    }
  }
`;

export const CREATEPRODUCTNAVIGATION = gql`
  mutation CreateProductNavigation($input: CreateProductNavigationInput!) {
    createProductNavigation(input: $input) {
      success
    }
  }
`;

export const CREAT_AD_LOG = gql`
  mutation CreateAdLog($input: CreateAdLogInput!) {
    createAdLog(input: $input) {
      id
    }
  }
`;

//  CART
export const CLEAR_MENU_CART = gql`
  mutation ClearMenuCart($input: ClearMenuCartInput!) {
    clearMenuCart(input: $input) {
      success
    }
  }
`;

export const ADD_TO_MENU_CART = gql`
  mutation AddToMenuCart($input: AddToMenuCartInput!) {
    addToMenuCart(input: $input) {
      count
    }
  }
`;

export const REMOVE_FROM_MENU_CART = gql`
  mutation RemoveFromMenuCart($input: RemoveFromMenuCartInput!) {
    removeFromMenuCart(input: $input) {
      count
    }
  }
`;

export const UPDATE_MENU_CART_QUATITY = gql`
  mutation UpdateMenuCartQuatity($input: UpdateMenuCartQuantityInput!) {
    updateMenuCartQuantity(input: $input) {
      success
    }
  }
`;

export const CREATE_MENU_ORDER = gql`
  mutation CreateMenuOrder($input: CreateMenuOrderInput!) {
    createMenuOrder(input: $input) {
      id
      reference
      orderDate
      customerInformations {
        firstName
        lastName
        email
        address {
          id
          postalCode
          country {
            id
            name
          }
          city {
            id
            name
          }
        }
        phone {
          countryCode
          number
        }
        position
      }
      customerNotes
      orderStatus
      details {
        count
        products {
          product {
            id
            names {
              id
              languageCode
              value
            }
            shortDescriptions {
              id
              languageCode
              value
            }
            longDescriptions {
              id
              languageCode
              value
            }
          }
        }
        originalTotalPrice {
          value
          currency {
            id
            code
          }
        }
        totalDiscountedCost {
          value
          currency {
            id
            code
          }
        }
        totalDeliveredCost {
          value
          currency {
            id
            code
          }
        }
        totalPrice {
          value
          currency {
            id
            code
          }
        }
      }
    }
  }
`;

export const INIT_PAYMENT_SKIPCASH = gql`
  mutation initiatePaymentSkipcash($input: InitiatePaymentSkipCashInput!) {
    initiatePaymentSkipcash(input: $input) {
      payUrl
    }
  }
`;
