import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { X, Search as SearchIcon } from "react-feather";
import { useQuery } from "@apollo/react-hooks";

import {
  GetAccessMenuDetailQuery,
  GetAccessMenuDetailQueryVariables,
  GetMenuThemeQuery,
  GetMenuThemeQueryVariables,
} from "../api/types";

import { GETMENU, GETMENUTHEME } from "../api/query.api";
// eslint-disable-next-line import/no-cycle
import { LanguageSelector, Cart, Title } from ".";
import { translate } from "../utils/dictionary";
import { URL } from "../api/config";

import {
  localDomain,
  localIdentifier,
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../Graphql/apolloClient";

type AppbarProps = {
  hideMobile?: boolean;
  handleSubmitSearch?: (search: string) => void;
};

const Appbar: React.FC<AppbarProps> = ({ hideMobile, handleSubmitSearch }) => {
  //   const router = useRouter();
  //   const { menu: menuIdentifier } = router.query;
  //   const language = router.query?.language as string;
  //   const keyword = router.query?.search as string;
  const history = useHistory();
  const { search: urlParams } = useLocation();

  const [search, setSearch] = React.useState("");
  const [showSearch, setShowSearch] = React.useState(false);

  // QUERIES
  const { data: localState } = useQuery<GetLocalStateQuery>(GET_LOCAL_STATE);
  const language = localState?.language || "EN";
  const localShop = localState?.shop;

  const { data: menuData } = useQuery<
    GetAccessMenuDetailQuery,
    GetAccessMenuDetailQueryVariables
  >(GETMENU, {
    variables: {
      input: { domain: localDomain(), identifier: localIdentifier() },
    },
  });
  const menuDetails = menuData?.getAccessMenuDetail?.accessMenu;

  const { data: themeData } = useQuery<
    GetMenuThemeQuery,
    GetMenuThemeQueryVariables
  >(GETMENUTHEME, {
    variables: {
      input: { menu: menuData?.getAccessMenuDetail.accessMenu?.id || "" },
    },
  });
  const menuTheme = themeData?.getMenTheme?.menuTheme;
  // show bar in mobile if search got a keyword
  useEffect(() => {
    const params = new URLSearchParams(urlParams);
    const keyword = params.get("keyword");
    if (keyword) {
      setShowSearch(true);
      setSearch(keyword);
    }
  }, [urlParams]);

  const handleOnChangeSearch = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (!e) {
      setSearch("");
    } else {
      e.preventDefault();
      setSearch(e.target.value);
    }
  };

  const handleShowSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (handleSubmitSearch) {
      handleSubmitSearch(search);
    } else {
      history.push(`/${localIdentifier()}/menu/search?keyword=${search}`);
    }
  };

  return (
    <Container hideMobile={hideMobile}>
      <Title
        title={
          menuDetails?.titles?.[
            menuDetails?.titles.findIndex(
              (lang) => lang.languageCode === language
            ) || 0
          ]?.value || ""
        }
        bg={
          menuTheme?.titleBackground?.[
            menuTheme?.titleBackground.findIndex(
              (decoration) => decoration.kind === "MENUTITLE"
            )
          ].value || "#"
        }
        className="title"
        onClick={() => {
          history.push(`/${localIdentifier()}/menu`);
        }}
      />
      <Search>
        <SearchIcon color="#949494" />
        <form onSubmit={handleOnSubmit}>
          <input
            type="text"
            name="search"
            className="input__search"
            placeholder={translate(language, 39)}
            onChange={(e) => handleOnChangeSearch(e)}
            value={search}
          />
        </form>
        {!!search && (
          <button
            type="button"
            className="button__cancel"
            onClick={() => handleOnChangeSearch()}
          >
            <X color="#444444" size="14" strokeWidth="3" />
          </button>
        )}
      </Search>
      <SearchBtn id="searchFAB" onClick={() => handleShowSearch()}>
        {showSearch ? <X color="#ffffff" /> : <SearchIcon color="#ffffff" />}
      </SearchBtn>
      {localShop === "ON" && <Cart id={menuDetails?.id || ""} />}
      {menuDetails?.languages && menuDetails?.languages?.length > 0 && (
        <LanguageSelector />
      )}
      <SearchArea show={showSearch}>
        <SearchIcon color="#949494" />
        <form onSubmit={handleOnSubmit}>
          <input
            type="text"
            name="search"
            className="input__search"
            placeholder={translate(language, 39)}
            onChange={handleOnChangeSearch}
            value={search}
          />
        </form>
      </SearchArea>
    </Container>
  );
};

type ContainerProps = { hideMobile?: boolean };

const Container = styled.header<ContainerProps>`
  display: ${(props) => (props?.hideMobile ? "none" : "grid")};
  grid-template-columns: 50px 1fr 50px;
  grid-template-areas: "language title searchBtn" "search search search";
  grid-area: appbar;
  justify-items: center;
  align-items: center;
  padding: 0 20px;
  .title {
    grid-area: title;
  }
  @media only screen and (min-width: 930px) {
    display: grid;
    grid-template-columns: 220px 1fr 440px 50px 60px;
    grid-template-areas: "title . search language cart";
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
    padding: 0 20px;
  }
`;
export const SearchBtn = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) =>
    theme.circularButtonBackground
      ? `url(${URL}${theme.circularButtonBackground})`
      : "rgba(127, 127, 127, 0.3)"};
  background-size: contain;
  border: 0;
  outline: none;
  border-radius: 50%;

  svg {
    stroke: #000000;
  }
  @media only screen and (min-width: 930px) {
    display: none;
  }
`;

export const Search = styled.div`
  width: 400px;
  display: none;
  top: 51px;
  ${(props) =>
    props.theme.direction === "RTL" ? "left: 195px;" : "right: 195px;"}
  grid-area: search;
  margin: ${(props) =>
    props.theme.direction === "RTL" ? "0 auto 0 0;" : "0 0 0 auto;"};
  padding: 0 20px;
  transition: 0.2s all ease-in-out;
  overflow: hidden;
  svg {
    width: 18px;
    position: absolute;
    top: 5px;
    ${(props) =>
      props.theme.direction === "RTL" ? "right: 29px" : "left: 29px"};
    z-index: 9;
    opacity: 0.3;
  }
  .input__search {
    width: calc(100% - 35px);
    height: 35px;
    padding: ${(props) =>
      props.theme.direction === "RTL" ? "0 35px 0 0" : "0 0 0 35px"};
    font-family: "Cairo";
    font-size: 15px;
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
    color: #3a3a46;
    border: 0;
    border-radius: 8px;
    outline: none;
    background-color: #ffffff;
    transition: all 0.2s ease-in-out;
  }
  .button__cancel {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 7.5px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0;
    outline: none;
    background-color: rgba(219, 216, 213, 0.3);
    svg {
      top: unset;
      left: unset;
    }
  }
  @media only screen and (min-width: 930px) {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    display: block;
  }
`;

type SearchType = {
  show: boolean;
};

export const SearchArea = styled.div<SearchType>`
  height: ${(props) => (props.show ? "50px" : "0")};
  width: 100%;
  max-width: 690px;
  position: relative;
  grid-column: 1 / -1;
  margin: auto;
  transition: 0.2s all ease-in-out;
  overflow: hidden;
  svg {
    width: 18px;
    position: absolute;
    top: 5px;
    z-index: 9;
    ${(props) =>
      props.theme.direction === "RTL" ? "left: 15px" : "right: 15px"};
    opacity: 0.3;
  }
  .input__search {
    height: 35px;
    width: 100%;
    padding: ${(props) =>
      props.theme.direction === "RTL" ? "0 20px 0 0" : "0 0 0 20px"};
    background-color: #ffffff;
    border: 0;
    border-radius: 8px;
    font-family: "Cairo";
    font-size: 15px;
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
    color: #3a3a46;
    box-sizing: border-box;
    outline: none;
    transition: all 0.2s ease-in-out;
  }
  @media only screen and (min-width: 930px) {
    display: none;
  }
`;

export default Appbar;
