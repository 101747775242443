import { ApolloClient, InMemoryCache, makeVar } from "@apollo/client";
import gql from "graphql-tag";

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        language: {
          read() {
            return localLanguage();
          },
        },
        shop: {
          read() {
            return localShop();
          },
        },
        precision: {
          read() {
            return localPrecision();
          },
        },
        domain: {
          read() {
            return localDomain();
          },
        },
        identifier: {
          read() {
            return localIdentifier();
          },
        },
        podIsEnabled: {
          read() {
            return localPodIsEnabled();
          },
        },
        paymentMethods: {
          read() {
            return localPaymentMethods();
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  cache,
  // clientState: {
  //   defaults: {
  //     language: "EN",
  //     shop: "OFF",
  //     precision: "1",
  //     domain: "",
  //     identifier: "",
  //   },
  // },
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
});

export type GetLocalStateQuery = {
  language: string;
  shop: string;
  precision: string;
  domain: string;
  identifier: string;
  podIsEnabled: boolean;
  paymentMethods: string[];
};

export const GET_LOCAL_STATE = gql`
  query @client {
    language
    shop
    precision
    domain
    identifier
    podIsEnabled
    paymentMethods
  }
`;

// local state
export const localLanguage = makeVar("EN");
export const localShop = makeVar("OFF");
export const localPrecision = makeVar("1");
export const localDomain = makeVar("");
export const localIdentifier = makeVar("");
export const localPodIsEnabled = makeVar<boolean>(false);
export const localPaymentMethods = makeVar<string[] | null | undefined>([]);

export default client;
