import { FilePayload, ProductDetailsPayload } from "../../api/types";

export const getProductUnit = (kind: string) => {
  switch (kind) {
    case "KILOGRAM":
      return "kg";
    case "METER":
      return "m";
    case "GRAM":
      return "100g";
    default:
      return "";
  }
};

export const copyLink = () => {
  const el: any = document.createElement("textarea");
  el.value = window.location.href;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
};

export const getProductPicture = (productInfo: ProductDetailsPayload) => {
  if (productInfo.copiedPictures && productInfo.copiedPictures.length > 0) {
    return productInfo.copiedPictures.filter(
      (picture: FilePayload) => picture.quality === "HIGH"
    )?.[0]?.fileUrl;
  }
  if (productInfo.picture?.fileUrl) {
    return productInfo.picture?.fileUrl;
  }
  return undefined;
};
