import React, { FC } from "react";
import styled from "styled-components";

type CheckboxProps = {
  id: string;
  label: JSX.Element | string;
  error?: boolean;
} & Omit<React.AllHTMLAttributes<HTMLInputElement>, "label">;

const Checkbox: FC<CheckboxProps> = ({ id, label, error, ...restProps }) => {
  return (
    <Wrapper error={error}>
      <input id={`checkbox-${id}`} type="checkbox" {...restProps} />
      <label htmlFor={`checkbox-${id}`}>{label}</label>
    </Wrapper>
  );
};

type WrapperProps = {
  error?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  margin: 2px;
  input[type="checkbox"] {
    opacity: 0;
    width: 20px;
    height: 20px;
    margin: 0 7px 0 0;
    + label {
      position: relative;
      display: inline-block;
      line-height: 28px;
      color: #42424a;
      font-weight: 400;
      font-family: ${(props) =>
        props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        display: inline-block;
        ${({ theme }) =>
          theme.direction === "RTL" ? "right: -27px" : "left: -27px"};
        top: 4px;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        background: #dadbdd;
        ${({ error }) =>
          error && "box-shadow: 0 0px 5px rgba(255, 35, 35, 0.6)"};
      }
      &::after {
        content: "";
        position: absolute;
        display: none;
        top: 6px;
        ${({ theme }) =>
          theme.direction === "RTL" ? "right: -20px" : "left: -20px"};
        width: 4px;
        height: 9px;
        border: 3px solid #ffffff;
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);
      }
    }
    &:checked {
      + label {
        &::after {
          display: inline-block;
        }
        &::before {
          background: #000000;
        }
      }
    }
    &:focus {
      + label::before {
        box-shadow: 0 0px 5px rgba(35, 141, 255, 0.4);
      }
    }
  }
`;

export default Checkbox;
