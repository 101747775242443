import styled from "styled-components";

type CookieType = {
  show: boolean | undefined;
};

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    max-width: 500px;
    width: 80vw;
  }
  p {
    font-family: "Cairo";
    padding: 0 60px;
    text-align: center;
    line-height: 20px;
  }
  button.refresh {
    height: 35px;
    width: 120px;
    grid-column: span 2;
    background: black;
    color: white;
    border: 0;
    border-radius: 10px;
    margin: 0 10px 60px 10px;
    font-size: 15px;
    font-family: "Cairo", Cambria, Cochin, Georgia, Times, "Times New Roman",
      serif;
  }
`;

export const Cookie = styled.div<CookieType>`
  width: 80vw;
  max-width: 350px;
  background: white;
  position: fixed;
  bottom: ${(props) => (props.show === true ? "3vh" : "-50vh")};
  opacity: 0;
  z-index: 999;
  right: 10vw;
  border-radius: 15px;
  display: none;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.1);
  ${(props) =>
    props.show === true && props.show
      ? "animation: show 0.5s cubic-bezier(0.32, 0.53, 0, 1.14) 2s 1 forwards"
      : "animation: hide 1s ease-out 1 forwards"};
  img {
    width: 30px;
    height: 30px;
    margin: 20px 10px 20px 20px;
    object-fit: cover;
  }
  label {
    font-family: "Cairo", Cambria, Cochin, Georgia, Times, "Times New Roman",
      serif;
    font-size: 14px;
    line-height: 16px;
    margin: auto;
    padding: 0 10px;
    display: block;
  }
  button {
    height: 35px;
    grid-column: span 2;
    background: black;
    color: white;
    border: 0;
    outline: none;
    border-radius: 9px;
    margin: 0 10px 10px 10px;
    font-size: 15px;
    font-family: "Cairo", Cambria, Cochin, Georgia, Times, "Times New Roman",
      serif;
  }
  @keyframes show {
    0% {
      bottom: -50vh;
      opacity: 1;
    }
    100% {
      opacity: 1;
      bottom: 3vh;
    }
  }
  @keyframes hide {
    0% {
      bottom: -3vh;
      opacity: 1;
    }
    100% {
      bottom: -50vh;
      opacity: 0;
    }
  }
  @media only screen and (min-width: 930px) {
    right: 3vh;
  }
`;

export const Loader = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  #container {
    width: 100px;
    height: 100px;
    margin: auto auto;
    transform: scale(0.6);
  }
  span {
    display: block;
    border-left: 5px solid;
    border-top-left-radius: 100%;
    border-top: 5px solid;
    margin: 5px;
    animation-name: Loader;
    animation-duration: 1500ms;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  .sp1 {
    border-left-color: ${(props) => props.color};
    border-top-color: ${(props) => props.color};
    width: 40px;
    height: 40px;
  }
  .sp2 {
    opacity: 0.6;
    border-left-color: ${(props) => props.color};
    border-top-color: ${(props) => props.color};
    width: 30px;
    height: 30px;
  }
  .sp3 {
    opacity: 0.3;
    width: 20px;
    height: 20px;
    border-left-color: ${(props) => props.color};
    border-top-color: ${(props) => props.color};
  }

  @keyframes Loader {
    0% {
      transform: rotate(0deg);
      transform-origin: right bottom;
    }
    25% {
      transform: rotate(90deg);
      transform-origin: right bottom;
    }
    50% {
      transform: rotate(180deg);
      transform-origin: right bottom;
    }
    75% {
      transform: rotate(270deg);
      transform-origin: right bottom;
    }
    100% {
      transform: rotate(360deg);
      transform-origin: right bottom;
    }
  }
`;
