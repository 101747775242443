import React, { FC, useEffect } from "react";
import styled from "styled-components";

type NavBarType = {
  show: boolean;
};

const NavBar: FC = () => {
  const [visible, setVisible] = React.useState(true);
  const [scroll, setScroll] = React.useState({
    prevScrollpos: window.pageYOffset,
  });

  useEffect(() => {
    const handleScroll = () => {
      const { prevScrollpos } = scroll;

      const currentScrollPos = window.pageYOffset;

      const visible = prevScrollpos > currentScrollPos;

      setScroll({ prevScrollpos: currentScrollPos });
      setVisible(visible);
    };

    window.addEventListener("scroll", handleScroll);
  });

  return (
    <Nav show={visible}>
      <div>nav</div>
    </Nav>
  );
};

const Nav = styled.div<NavBarType>`
  position: fixed;
  width: 180px;
  height: 60px;
  background-color: white;
  border-radius: 30px;
  bottom: ${(props) => (props.show === true ? "20px" : "-100px")};
  opacity: ${(props) => (props.show === true ? 1 : 0)};
  left: calc(50vw - 90px);
  transition: all 0.4s ease-in-out;
`;

export default NavBar;
