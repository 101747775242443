import React, { FC } from "react";
import { Share2 } from "react-feather";
import findIndex from "lodash/findIndex";
import { useQuery } from "@apollo/client";

import { getPrice } from "../../../utils/util";
import { URL } from "../../../api/config";
import { getProductUnit, getProductPicture } from "../ModalProduct--utils";
import Wrapper from "./TabMain--styles";

import { ProductDetailsPayload } from "../../../api/types";
import {
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../../../Graphql/apolloClient";

type TabMainProps = {
  share: (name: string) => void;
  product?: ProductDetailsPayload;
};

const TabMain: FC<TabMainProps> = ({ product, share }) => {
  const { data: localState } = useQuery<GetLocalStateQuery>(GET_LOCAL_STATE);
  const localLanguage = localState?.language || "EN";
  const localPrecision = localState?.precision || "1";
  const localShop = localState?.shop;

  const language: number = findIndex(product?.names, {
    languageCode: localLanguage,
  });

  return (
    <Wrapper shop={localShop === "ON"}>
      <div className="image-container">
        {product && getProductPicture(product) && (
          <img src={URL! + getProductPicture(product)} alt="modal" />
        )}
      </div>
      <div style={{ padding: "10px" }}>
        <div
          className={`${
            !product?.picture?.fileUrl && "off-content"
          } content-header`}
        >
          <h3>
            {product &&
              product.names &&
              product.names.length > 0 &&
              (product.names?.[language]?.value || "")}
          </h3>
          <button
            id="share"
            type="button"
            className="share"
            onClick={() => {
              share(product?.names?.[0].value || "Share");
            }}
          >
            <Share2 size="16" />
          </button>
        </div>
        <div className="price-container">
          {product?.discount?.price?.value && (
            <label className="price" htmlFor="price">
              {`${getPrice(
                product?.discount?.price?.value.toString(),
                localPrecision
              )} `}
              &nbsp;
              <span>
                {product?.discount?.price?.currency?.code || ""}
                {product?.unit?.kind !== "PIECE" && "/"}
                {getProductUnit(product?.unit?.kind || "")}
              </span>
            </label>
          )}
          {!!product?.price?.value && (
            <label
              htmlFor="discount"
              className={
                product?.discount?.price?.value ? "price discount" : "price"
              }
            >
              <span
                className={
                  product.discount?.price?.value ? "discounted-price" : ""
                }
              >
                {`${getPrice(
                  product.price?.value.toString(),
                  localPrecision
                )} `}
              </span>
              &nbsp;
              <span
                className={
                  product.discount?.price?.value ? "discounted-price" : ""
                }
              >
                {product.price?.currency?.code || ""}
                {!product?.discount?.price?.value &&
                  product?.unit?.kind !== "PIECE" &&
                  `/${getProductUnit(product?.unit?.kind || "")}`}
              </span>
            </label>
          )}
        </div>
        <div className="description-container">
          <label className="short-description" htmlFor="description">
            {product &&
              product.shortDescriptions &&
              product.shortDescriptions.length > 0 &&
              (product.shortDescriptions?.[language]?.value || "")}
          </label>
        </div>
        <p>
          {product &&
            product.longDescriptions &&
            product.longDescriptions.length > 0 &&
            product.longDescriptions[language] &&
            (product.longDescriptions?.[language]?.value || "")}
        </p>
      </div>
    </Wrapper>
  );
};

export default TabMain;
