export const searchNotFound = (language: string) => {
  if (language === "FR") {
    return `Aucun résultat trouvé!
    Nous sommes désolés, nous n'avons pas trouvé ce que vous cherchez`;
  }
  if (language === "AR") {
    return `!لم يتم العثور على نتيجة
      .نأسف لعدم العثور على ما تبحث عنه`;
  }
  return `No results found! We are sorry,
  we couldn't find what your're looking for.`;
};
