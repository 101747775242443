import styled from "styled-components";

type WrapperProps = {
  shop: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  max-height: 500px;
  padding-bottom: ${(props) => (props.shop ? "50px" : "0px")};
  overflow-y: scroll;
  .image-container {
    width: 100%;
    display: grid;
    border-radius: 20px;
    overflow: hidden;
    img {
      width: 100%;
      margin: auto;
      object-fit: cover;
      object-position: center;
    }
  }
  .off-content {
    margin-right: 50px;
  }
  .content-header {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 40px;
  }
  .share {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    order: ${(props) => (props.theme.direction === "RTL" ? "1" : "2")};
    background: #000000;
    border: 0;
    border-radius: 20px;
    svg {
      color: #ffffff;
    }
  }
  .availability {
    font-size: 12px;
    font-weight: bold;
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
  }
  .price-container {
    display: flex;
    align-items: baseline;
    margin: 0 15px 10px 15px;
  }
  .price {
    display: flex;
    margin: ${(props) =>
      props.theme.direction === "RTL" ? "0 0 0 15px" : "0 15px 0 0"};
    color: #000;
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
    font-weight: 600;
  }
  .discount {
    display: flex;
    flex-direction: ${(props) =>
      props.theme.direction === "RTL" ? "row-reverse" : "unset"};
    color: black;
    font-size: 14px;
    text-decoration: line-through;
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
  }
  .description-container {
    display: flex;
    flex-direction: column;
    align-content: ${(props) =>
      props.theme.direction === "RTL" ? "flex-end" : "flex-start"};
    padding: 0 15px;
    label.short-description {
      display: block;
      font-size: 13px;
      color: #979797;
      text-align: ${(props) =>
        props.theme.direction === "RTL" ? "right" : "left"};
      font-family: ${(props) =>
        props.theme.direction === "RTL"
          ? "Cairo"
          : props.theme.police.secondary};
      ::first-letter {
        ${(props) => (props.theme.direction === "RTL" ? "unset" : "uppercase")};
      }
    }
  }
`;

export default Wrapper;
