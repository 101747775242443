/* eslint-disable react/no-array-index-key */
//  libraries imports
import React, { FC, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";

//  local imports
import { Wrapper } from "./styles";
import { Item, Appbar } from "../../Commons";
import { searchNotFound } from "../../utils/messages";
import { GET_PRODUCTS, GETMENU } from "../../api/query.api";
import {
  GetProductsQuery,
  GetProductsQueryVariables,
  ProductPayload,
  FilePayload,
  GetAccessMenuDetailQuery,
  GetAccessMenuDetailQueryVariables,
} from "../../api/types";

import {
  GetLocalStateQuery,
  GET_LOCAL_STATE,
  localDomain,
  localIdentifier,
} from "../../Graphql/apolloClient";

// types
type ParamsType = {
  id: string;
};

type LocationType = {
  search: string;
};

const Search: FC = () => {
  const history = useHistory();
  const { id } = useParams<ParamsType>();
  const { search: urlParams } = useLocation<LocationType>();

  //  STATES
  const { data: localState } = useQuery<GetLocalStateQuery>(GET_LOCAL_STATE);
  const localLanguage = localState?.language || "EN";

  const { data: menuData } = useQuery<
    GetAccessMenuDetailQuery,
    GetAccessMenuDetailQueryVariables
  >(GETMENU, {
    variables: {
      input: { domain: localDomain(), identifier: localIdentifier() },
    },
  });

  const menu = menuData?.getAccessMenuDetail.accessMenu;

  const [
    searchProducts,
    { data: searchResult, loading: loadingSearch },
  ] = useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GET_PRODUCTS);

  useEffect(() => {
    const params = new URLSearchParams(urlParams);
    const keyword = params.get("keyword");
    if (keyword) {
      searchProducts({
        variables: { input: { menus: [menu?.id || ""], name: keyword } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  LOCAL VARIABLES
  const suggestions = searchResult?.getProducts.list;
  const language: number =
    menu?.languages?.findIndex((lang) => lang.languageCode === localLanguage) ||
    0;
  const searchMessage = searchNotFound(localLanguage);

  //  FUNCTIONS
  const handleItem = (item: ProductPayload) => {
    history.replace(
      `/${id}/menu/${item.category?.identifier}/${item.identifier}`
    );
  };

  const getSearchedProductPicture = (product: ProductPayload) => {
    if (product.copiedPictures && product.copiedPictures.length > 0) {
      return product.copiedPictures.filter(
        (picture: FilePayload) => picture.quality === "LOW"
      )?.[0]?.fileUrl;
    }
    if (product.picture?.fileUrl) {
      return product.picture?.fileUrl;
    }
    return menu?.logo;
  };

  return (
    <Wrapper>
      <Appbar
        handleSubmitSearch={(search) =>
          searchProducts({
            variables: { input: { menus: [menu?.id || ""], name: search } },
          })
        }
      />
      {!loadingSearch &&
        (suggestions && suggestions?.length > 0 ? (
          <div className="products-container__grid">
            {suggestions?.map((item, index) => {
              return (
                item.names?.[language]?.value && (
                  <Item
                    key={index}
                    display="GRID"
                    show={() => {
                      handleItem(item);
                    }}
                    data={item}
                    image={getSearchedProductPicture(item) || ""}
                  />
                )
              );
            })}
          </div>
        ) : (
          <div className="search--empty">{searchMessage}</div>
        ))}
    </Wrapper>
  );
};

export default Search;
