// libreries imports
import React, { FC } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { LogIn } from "react-feather";
import { useHistory } from "react-router-dom";
import _ from "lodash";

//  local imports
import { getCookie, getDomain } from "../../utils/util";
import { Wrapper, Separator } from "./style";
import { URL } from "../../api/config";
import { translate } from "../../utils/dictionary";
import {
  GetAccessMenuDetailQuery,
  GetAccessMenuDetailQueryVariables,
  CreateMenuNavigationMutation,
  CreateMenuNavigationMutationVariables,
  FilePayload,
  GetCompanyInformationsQuery,
  GetCompanyInformationsQueryVariables,
} from "../../api/types";
import { GETMENU, GET_COMPANY_INFORMATION } from "../../api/query.api";
import { LanguageSelector } from "../../Commons";
import { CREATMENUNAVIGATION } from "../../api/mutation.api";
import { ReactComponent as Digipages } from "../../Images/digipages.svg";
import { ReactComponent as Menume } from "../../Images/menume.svg";
import {
  localDomain,
  localIdentifier,
  GET_LOCAL_STATE,
  GetLocalStateQuery,
} from "../../Graphql/apolloClient";
import {
  Facebook,
  YouTube,
  WhatsApp,
  Instagram,
  Reddit,
  Twitter,
  Snapchat,
  LinkedIn,
  Pinterest,
  Tumblr,
  Periscope,
  Website,
} from "../../Images/social";

const SocialObject = {
  Facebook: <Facebook width="32" height="32" className="social__icon" />,
  YouTube: <YouTube width="32" height="32" className="social__icon" />,
  WhatsApp: <WhatsApp width="32" height="32" className="social__icon" />,
  Instagram: <Instagram width="32" height="32" className="social__icon" />,
  Reddit: <Reddit width="32" height="32" className="social__icon" />,
  Twitter: <Twitter width="32" height="32" className="social__icon" />,
  Snapchat: <Snapchat width="32" height="32" className="social__icon" />,
  LinkedIn: <LinkedIn width="32" height="32" className="social__icon" />,
  Pinterest: <Pinterest width="32" height="32" className="social__icon" />,
  Tumblr: <Tumblr width="32" height="32" className="social__icon" />,
  Periscope: <Periscope width="32" height="32" className="social__icon" />,
};

const Home: FC = () => {
  const history = useHistory();
  const { data: localState } = useQuery<GetLocalStateQuery>(GET_LOCAL_STATE);
  const localLanguage = localState?.language || "EN";

  const { data } = useQuery<
    GetAccessMenuDetailQuery,
    GetAccessMenuDetailQueryVariables
  >(GETMENU, {
    variables: {
      input: { domain: localDomain(), identifier: localIdentifier() },
    },
  });
  const menu = data?.getAccessMenuDetail.accessMenu;

  const { data: companyInformationData } = useQuery<
    GetCompanyInformationsQuery,
    GetCompanyInformationsQueryVariables
  >(GET_COMPANY_INFORMATION, {
    skip: !menu?.id,
    variables: { input: { menu: menu?.id! } },
  });

  const companyInformation =
    companyInformationData?.getCompanyInformations?.company;

  // MUTATIONS
  const [menuStat] = useMutation<
    CreateMenuNavigationMutation,
    CreateMenuNavigationMutationVariables
  >(CREATMENUNAVIGATION, {
    variables: {
      input: {
        kind: "MENU_ENTRY",
        device: getCookie(),
        menu: data?.getAccessMenuDetail?.accessMenu?.id || "",
      },
    },
  });

  // LOCAL VARIABLES
  const projectType = getDomain();

  const getFooter = () => {
    switch (projectType) {
      case "digimenu":
        return {
          name: "digimenu",
          logo: <Digipages width="22" fill="#ffffff" />,
        };
      case "menume":
        return { name: "menume", logo: <Menume width="22" fill="#ffffff" /> };
      default:
        return {
          name: "digipages",
          logo: <Digipages width="22" fill="#ffffff" />,
        };
    }
  };

  // eslint-disable-next-line consistent-return
  const getInfo = (kind: string) => {
    const infoIndex: number = _.findIndex(menu?.characteristics, {
      kind,
    });
    if (menu?.characteristics && infoIndex !== -1) {
      return menu?.characteristics[infoIndex].value;
    }
  };

  const getLogo = () => {
    if (menu?.copiedLogos && menu?.copiedLogos?.length > 0) {
      return menu.copiedLogos.filter(
        (logo: FilePayload) => logo.quality === "HIGH"
      )?.[0]?.fileUrl;
    }
    return menu?.logo;
  };

  const getHref = () => {
    switch (projectType) {
      case "digimenu":
        return "https://digipages.net";
      case "menume":
        return "https://menume.fr";
      default:
        return "https://digipages.net";
    }
  };

  const handleClickMenu = () => {
    menuStat().then(() => {
      history.push(`${localIdentifier()}/menu`);
    });
  };

  return (
    <Wrapper>
      <div className="language-btn">
        {menu?.languages && menu?.languages?.length > 0 && <LanguageSelector />}
      </div>
      <div className="container">
        <div className="container__logo">
          <img src={URL! + getLogo() || ""} alt="logo" className="logo" />
        </div>
        <Separator />
        <p className="__description">
          {menu?.descriptions?.[
            menu?.descriptions.findIndex(
              (item) => item.languageCode === localLanguage
            )
          ]?.value || " "}
        </p>
        <Separator />
        <button
          type="button"
          onClick={handleClickMenu}
          className="button__access"
        >
          <span>{translate(localLanguage, 0)}</span>
          <LogIn size={18} />
        </button>
        <Separator />
        <p className="__info">{getInfo("WIFI_PASSWORD")}</p>
        <div className="social__container">
          {companyInformation?.website && (
            <a
              href={companyInformation?.website}
              target="_blank"
              rel="noreferrer"
            >
              <Website width="32" height="32" className="social__icon" />
            </a>
          )}
          {companyInformation?.socials?.map((social) => {
            if (social.kind?.name) {
              return (
                <a
                  key={social.kind.id}
                  href={social.value || ""}
                  target="_blank"
                  rel="noreferrer"
                >
                  {SocialObject[social.kind.name as keyof typeof SocialObject]}
                </a>
              );
            }
            return null;
          })}
        </div>
        {companyInformation?.address?.roadName && (
          <p className="__info">{companyInformation.address.roadName}</p>
        )}
      </div>

      <a
        className="footer__link"
        href={getHref()}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div style={{ position: "relative" }}>
          <div className="shadow" />
          <span className="footer__info">
            {getFooter()?.logo}
            &nbsp; Powered by {getFooter()?.name}
          </span>
        </div>
      </a>
    </Wrapper>
  );
};

export default Home;
