/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
//  libraries import
import React, { useState, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";

//  local imports
import { getData, getPathName } from "./utils/util";
import { Loader, Error, Cookie } from "./style";
import { Layout } from "./Screens";
import cookieIcon from "./Images/cookie.svg";
import errorBg from "./Images/error.png";
import { getFont } from "./utils/fonts";
import { translate } from "./utils/dictionary";

import client, {
  localPrecision,
  localDomain,
  localIdentifier,
  localLanguage,
  localShop,
  localPodIsEnabled,
  localPaymentMethods,
  GET_LOCAL_STATE,
} from "./Graphql/apolloClient";
import {
  GetAccessMenuDetailQuery,
  GetAccessMenuDetailQueryVariables,
  GetMenuThemeQueryVariables,
  GetMenuThemeQuery,
  GetMainMenuQuery,
  GetMainMenuQueryVariables,
  DescriptionPayload,
} from "./api/types";
import { GETMENU, GETMENUTHEME, GET_MAIN_MENU } from "./api/query.api";
import { URL } from "./api/config";

const App: React.FC = () => {
  const history = useHistory();
  //  extract domain and identifier from url
  const domain = getData();
  const identifier = window.location.pathname?.split("/")[1];
  const [language, setLanguage] = useState<string>("");
  const [MTheme, setMTheme] = useState<GetMenuThemeQuery>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [cookiePopup, setCookiePopup] = React.useState<boolean | undefined>(
    undefined
  );
  const [menuDescription, setMenuDescription] = React.useState<
    DescriptionPayload[]
  >([]);
  const [menuLogo, setMenuLogo] = React.useState<string | undefined>();
  const [pathname, setPathname] = React.useState<string>(
    window.location.pathname
  );

  window.addEventListener("click", () => {
    if (pathname !== getPathName())
      window.dispatchEvent(new Event("linkchanged"));
  });

  window.addEventListener("linkchanged", () => {
    setPathname(window.location.pathname);
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  !identifier &&
    client
      .query<GetMainMenuQuery, GetMainMenuQueryVariables>({
        query: GET_MAIN_MENU,
        variables: { input: { domain } },
      })
      .then((response) => {
        const mainIdentifier = response?.data?.getMainMenu?.identifier || "";
        history.push(`/${mainIdentifier}`);
      })
      .catch(() =>
        console.log("Sorry this account doesn't have a main website!")
      );

  localDomain(domain);
  localIdentifier(identifier);

  //  manage cookies
  const { cookie } = document;

  useEffect(() => {
    const generateToken = (): string => {
      return Math.random().toString(36).substr(2);
    };
    const cookieValues = cookie?.split(";");
    if (cookieValues[0] === "") {
      const generatedToken =
        generateToken() + generateToken() + generateToken() + generateToken();
      const NewCookie = `token=${generatedToken}`;
      document.cookie = NewCookie;
      document.cookie = "agreeToCookies=0";
    }
  }, [cookie]);

  useEffect(() => {
    if (document.readyState !== "complete") {
      setLoaded(false);
      const cookieValues = cookie?.split(";");
      const concent = cookieValues?.[1]?.split("=");
      if (concent?.[1] === "0") {
        setCookiePopup(true);
      }
    }
  }, [cookie, setLoaded]);

  // ****** Update language /******
  client
    .watchQuery({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setLanguage(data.language);
      },
      error: (e) => console.error(e),
    });

  // useEffect(() => {
  //   setLanguage(localLanguage());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [localLanguage()]);
  // ****** END UPDATE LANGUAGE ******

  const handleCookie = () => {
    const cookieValues = cookie?.split(";");
    const concent = cookieValues?.[1]?.split("=");
    if (concent?.[1] === "0") {
      const newCookie = `${concent[0]}=1; ${cookieValues[0]};`;
      document.cookie = newCookie;
    }
    setCookiePopup(false);
  };

  useEffect(() => {
    if (identifier) {
      client
        .query<GetAccessMenuDetailQuery, GetAccessMenuDetailQueryVariables>({
          query: GETMENU,
          variables: {
            input: { domain, identifier },
          },
          // fetchPolicy: "network-only",
        })
        .then((promise) => {
          const accessMenu = promise?.data?.getAccessMenuDetail?.accessMenu;
          const onlineStore =
            accessMenu?.characteristics?.[
              accessMenu?.characteristics.findIndex(
                (item) => item.kind === "ONLINE_SALE"
              )
            ]?.value || "OFF";
          // why the language
          if (promise) {
            localLanguage(
              accessMenu?.languages?.filter((lang) => lang.isMain)?.[0]
                ?.languageCode || "EN"
            );
            localPrecision(
              accessMenu?.characteristics?.[
                accessMenu?.characteristics.findIndex(
                  (item) => item.kind === "PRICE_PRECISION"
                )
              ]?.value || "1"
            );
            localShop(onlineStore);
            localPodIsEnabled(Boolean(accessMenu?.podIsEnabled));
            localPaymentMethods(accessMenu?.paymentMethods);
          }
          localStorage.setItem("menuId", accessMenu?.id || "");
          setMenuLogo(`${URL}${accessMenu?.logo}`);
          setMenuDescription(accessMenu?.descriptions || []);
          if (promise)
            client
              .query<GetMenuThemeQuery, GetMenuThemeQueryVariables>({
                query: GETMENUTHEME,
                variables: {
                  input: {
                    menu: accessMenu?.id || "",
                  },
                },
                // fetchPolicy: "network-only",
              })
              .then((themePromise) => {
                if (themePromise) setMTheme(themePromise.data);
              })
              .then(() => {
                setLoaded(true);
              })
              .catch(() => {
                console.log(
                  "Sorry! This website's theme has not yet been set up! try again later."
                );
                setError(true);
              });
        })
        .catch(() => {
          console.log(
            "Sorry! The website you requested is down at the moment, please retry later."
          );
          setError(true);
        });
    }
  }, [domain, identifier]);

  const menuTheme = MTheme?.getMenTheme.menuTheme;

  const bg =
    (URL || "") +
    MTheme?.getMenTheme.menuTheme?.backgroundStyle?.[1].value?.[0];

  const bgColor =
    MTheme?.getMenTheme.menuTheme?.backgroundStyle?.[0].value?.[0] || "";

  const bgHome =
    (URL || "") +
    menuTheme?.backgroundStyle?.[
      menuTheme?.backgroundStyle?.findIndex(
        (background) => background.kind === "BACKGROUNDHOME"
      )
    ]?.value?.[0];
  const bgColorCard = menuTheme?.backgroundStyle?.find(
    (background) => background.kind === "BACKGROUND_CARD"
  )?.value?.[0];

  const theme = {
    colorBackground: bgColor,
    imageBackground: bg,
    imageBackgroundHome: bgHome,
    colorBackgroundCard: bgColorCard,
    buttonBackground: menuTheme?.buttonsStyle?.find(
      (buttonStyle) => buttonStyle.kind === "RECTANGULAR_BUTTON"
    )?.backgroundImage,
    circularButtonBackground: menuTheme?.buttonsStyle?.find(
      (buttonStyle) => buttonStyle.kind === "CIRCULAR_BUTTON"
    )?.backgroundImage,
    police: {
      primary: getFont(
        MTheme?.getMenTheme.menuTheme?.fontStyles?.[0].value || ""
      ),
      secondary: getFont(
        MTheme?.getMenTheme.menuTheme?.fontStyles?.[1].value || ""
      ),
    },
    paletteColors: {
      primary: MTheme?.getMenTheme.menuTheme?.colorPalette?.[0].value,
      secondary: MTheme?.getMenTheme.menuTheme?.colorPalette?.[1].value,
      accent: MTheme?.getMenTheme.menuTheme?.colorPalette?.[2].value,
    },
    direction: language === "AR" ? "RTL" : "LTR",
    CategoryfontSize:
      MTheme?.getMenTheme.menuTheme?.titleBackground?.[1].fontSize,
  };

  const getTitle = useMemo(() => {
    // switch (getDomain()) {
    //   case "digimenu":
    //     return "DigiMenu";
    //   case "digipages":
    //     return "Digipages";
    //   case "menume":
    //     return "Menume";
    //   default:
    //     return "Digipages";
    // }
    let path = getData();
    pathname
      .split("/")
      .slice(3)
      .forEach((e) => {
        path += ` | ${e}`;
      });
    return path;
  }, [pathname]);

  const getDescription = useMemo(() => {
    let description = "";
    if (menuDescription.length > 0) {
      menuDescription.forEach((e) => {
        if (e && e.languageCode === "EN") description = e.value || "";
      });
    }
    return description;
  }, [menuDescription]);

  return (
    <ApolloProvider client={client}>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <meta name="description" content={getDescription} /> */}
        <meta name="description" content="Restaurant Contactless Digital Menu powered by Digipages TLD"/>
        <meta name="robots" content="index, follow"/>
        <link rel="icon" type="image/png" sizes="32x32" href={menuLogo} />
        <title>{getTitle}</title>
      </Helmet>
      <Cookie show={cookiePopup}>
        <img src={cookieIcon} alt="cookie" />
        <label htmlFor="cookie">
          Ce site utilise les cookies pour qu`&apos;`on puisse mieux vous
          servire
        </label>
        <button type="button" onClick={() => handleCookie()}>
          Compri
        </button>
      </Cookie>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <Switch>
              {error ? (
                <Error>
                  <img src={errorBg} alt="error" />
                  <p>{translate(language || "", 5)}</p>
                  <button
                    type="button"
                    className="refresh"
                    onClick={() => window.location.reload()}
                  >
                    {translate(language || "", 4)}
                  </button>
                </Error>
              ) : loaded ? (
                <Route path="/" component={Layout} />
              ) : (
                <Loader color="#00004E">
                  <div id="container">
                    <span className="sp1">
                      <span className="sp2">
                        <span className="sp3" />
                      </span>
                    </span>
                  </div>
                </Loader>
              )}
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
