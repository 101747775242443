/* eslint-disable react/no-array-index-key */
import React, { FC } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { CartProductPayload, CustomerInformationsInput } from "../api/types";

//  TYPES
type ReceiptType = {
  products: CartProductPayload[] | null | undefined;
  language: number;
  Receiptrefrence: string;
  delivery: string;
  total: string;
  note: string;
  customer: CustomerInformationsInput | null | undefined;
};

Font.register({
  family: "Libre Barcode 128",
  src:
    "https://fonts.gstatic.com/s/librebarcode128/v9/cIfnMbdUsUoiW3O_hVviCwVjuLtXeJ_A_gMk0izH.ttf",
});

// STYLES
const styles = StyleSheet.create({
  image: {
    width: "16%",
    padding: 10,
    marginLeft: "42%",
    marginTop: "10%",
    backgroundColor: "grey",
  },
  header: {
    marginTop: "5%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  headerTitle: {
    textAlign: "center",
    width: "33%",
    fontSize: "24",
  },
  refrence: {
    textAlign: "left",
    width: "33%",
    fontSize: "16",
    color: "#757575",
  },
  tableHeader: {
    width: "90%",
    marginTop: "20px",
    marginLeft: "5%",
    backgroundColor: "#f9f9f9",
    borderRadius: "10",
    fontSize: "13",
    display: "flex",
    flexDirection: "row",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "2%",
    paddingBottom: "2%",
    color: "#757575",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  product: {
    width: "70%",
  },
  suplement: {
    color: "#8b8a8e",
  },
  price: {
    width: "30%",
    textAlign: "right",
  },
  seperator: {
    width: "100%",
    height: "1px",
    marginTop: "2%",
    marginBottom: "2%",
    backgroundColor: "#dbdbdb",
  },
  tableRow: {
    width: "90%",
    marginLeft: "5%",
    fontSize: "13",
    display: "flex",
    flexDirection: "row",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "2%",
    paddingBottom: "2%",
    color: "#757575",
  },
  delivery: {
    width: "70%",
  },
  cost: {
    width: "30%",
    textAlign: "right",
  },
  tableRowDescription: {
    width: "90%",
    marginLeft: "5%",
    fontSize: "13",
    display: "flex",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "2%",
    borderRadius: "10",
    paddingBottom: "2%",
    color: "#757575",
    backgroundColor: "#f9f9f9",
  },
  info: {
    margin: "20px 5% 0",
    backgroundColor: "#f9f9f9",
    padding: "20px",
    borderRadius: "10",
    fontSize: "14",
    color: "#212121",
  },
  infoTitle: {
    paddingBottom: "5px",
  },
  infoDescription: {
    fontSize: "13",
    color: "#757575",
    paddingTop: "8px",
  },
  barreCode: {
    width: "33%",
    textAlign: "right",
    fontFamily: "Libre Barcode 128",
    fontSize: "48",
  },
  note: {},
});

const getProductUnit = (quantity: number, kind: string) => {
  switch (kind) {
    case "KILOGRAM":
      return `${quantity}kg`;
    case "METER":
      return `${quantity}m`;
    case "GRAM":
      return `${quantity * 100}g`;
    default:
      return `${quantity}`;
  }
};

const Receipt: FC<ReceiptType> = ({
  products,
  language,
  Receiptrefrence,
  delivery,
  total,
  customer,
  note,
}) => (
  <Document>
    <Page size="A4">
      <View style={styles.header}>
        <Text style={styles.refrence}>Reference: {Receiptrefrence}</Text>
        <Text style={styles.headerTitle}>Ticket</Text>
        <Text style={styles.barreCode}>{Receiptrefrence}</Text>
      </View>
      <View style={styles.info}>
        <Text style={styles.infoTitle}>
          {`${customer?.firstName} ${customer?.lastName}`}
        </Text>
        <Text style={styles.infoDescription}>
          Phone: {customer?.phone?.number}
        </Text>
        <Text style={styles.infoDescription}>Email: {customer?.email}</Text>
        <Text style={styles.infoDescription}>
          Address: {customer?.address?.roadNames?.[0]?.value}
        </Text>
      </View>
      <View style={styles.tableHeader}>
        <Text>Order</Text>
      </View>
      {products?.map((product, index) => {
        return (
          <View style={styles.tableRow} key={index}>
            <Text style={styles.product} fixed>
              {product.product?.names?.[language]?.value || ""}{" "}
              {product.supplements?.map((suppelment) => (
                <Text style={styles.supplement}>
                  +{suppelment.names?.[language]?.value || ""}{" "}
                </Text>
              ))}
            </Text>
            <Text style={styles.price} fixed>
              {product.quantity &&
                product.product?.unit?.kind &&
                getProductUnit(
                  product.quantity || 0,
                  product.product?.unit?.kind || ""
                )}{" "}
              x{" "}
              {`${product.unitPrice?.value || ""} ${
                product.unitPrice?.currency?.code || ""
              }`}
            </Text>
          </View>
        );
      })}
      <View style={styles.tableRowDescription}>
        {delivery ? (
          <>
            <View style={styles.row}>
              <Text style={styles.delivery} fixed>
                Delivery cost
              </Text>
              <Text style={styles.cost} fixed>
                {delivery}
              </Text>
            </View>
            <View style={styles.seperator} fixed />
          </>
        ) : null}
        <View style={styles.row}>
          <Text style={styles.delivery} fixed>
            Total
          </Text>
          <Text style={styles.cost} fixed>
            {total}
          </Text>
        </View>
      </View>
      <View style={styles.note}>
        <Text style={styles.tableHeader} fixed>
          {`Note:  ${note || ""}`}
        </Text>
      </View>
      {/* <View style={styles.info}>
        <Text style={styles.infoTitle}>Company Name</Text>
        <Text style={styles.infoDescription}>Téléphone: 99 XXX XXX</Text>
        <Text style={styles.infoDescription}>Email: contact@mail.com</Text>
      </View> */}
    </Page>
  </Document>
);

export default Receipt;
