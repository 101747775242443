import React, { FC } from "react";

import { Wrapper, Container, MasterContainer } from "./style";

import { Appbar, CategoriesList } from "../../Commons";

const Menu: FC = () => {
  return (
    <MasterContainer>
      <Container>
        <Wrapper>
          <Appbar />
          <CategoriesList position="HOME" />
        </Wrapper>
      </Container>
    </MasterContainer>
  );
};

export default Menu;
