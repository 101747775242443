import gql from "graphql-tag";

// get localState
export const GET_LOCAL_STATE = gql`
  {
    language @client
    shop @client
    precision @client
    domain @client
    identifier @client
  }
`;
//GET MENU ORDER STATUS
export const GET_MENU_ORDER_STATUS = gql`
  query GetMenuOrderStatus($input: GetMenuOrderStatusInput!) {
    getMenuOrderStatus(input: $input) {
      status
    }
  }
`;

// GET MENU DETAILS
export const GETMENU = gql`
  query GetAccessMenuDetail($input: GetDetailAccessMenuInput!) {
    getAccessMenuDetail(input: $input) {
      accessMenu {
        id
        logo
        identifier
        accessCode
        urlCode
        copiedLogos {
          id
          quality
          kind
          fileName
          fileUrl
        }
        titles {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        languages {
          id
          languageCode
          isMain
        }
        characteristics {
          kind
          value
        }
        categories {
          id
          display
          pictureIsVisible
          identifier
          picture {
            id
            kind
            fileName
            fileUrl
          }
          copiedPictures {
            id
            quality
            kind
            fileName
            fileUrl
          }
          names {
            id
            languageCode
            value
          }
          descriptions {
            id
            languageCode
            value
          }
          order
        }
        podIsEnabled
        paymentMethods
      }
    }
  }
`;

// GET MENU THEME
export const GETMENUTHEME = gql`
  query GetMenuTheme($input: GetMenuthemeInput!) {
    getMenTheme(input: $input) {
      menuTheme {
        id
        menu
        backgroundStyle {
          kind
          value
        }
        fontStyles {
          kind
          value
        }
        colorPalette {
          kind
          value
        }
        titleBackground {
          kind
          value
          fontSize
        }
        buttonsStyle {
          kind
          backgroundImage
        }
      }
    }
  }
`;

//  List menu ads
export const GETLISTMENUADS = gql`
  query GetAccessMenuAds($input: GetAccessMenuAdsInput!) {
    getAccessMenuAds(input: $input) {
      list {
        id
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        kind
        position
        pictures {
          cover {
            id
            kind
            fileName
          }
          poster {
            id
            kind
            fileName
          }
        }
        products {
          id
          identifier
          parentCategory
          names {
            id
            languageCode
            value
          }
          shortDescriptions {
            id
            languageCode
            value
          }
          longDescriptions {
            id
            languageCode
            value
          }
          price {
            value
            currency {
              id
              code
            }
          }
          discount {
            percentage
            price {
              currency {
                id
                code
              }
              value
            }
          }
          picture {
            id
            kind
            fileName
            fileUrl
          }
        }
        externalUrl
        startDate
        endDate
      }
    }
  }
`;

// getproduct details
export const GET_PRODUCT_DETAILS = gql`
  query GetProductDetails($input: GetProductDetailsInput!) {
    getProductDetails(input: $input) {
      product {
        id
        isAvailable
        names {
          id
          languageCode
          value
        }
        shortDescriptions {
          id
          languageCode
          value
        }
        longDescriptions {
          id
          languageCode
          value
        }
        unit {
          kind
          default
        }
        price {
          value
          currency {
            id
            code
          }
        }
        discount {
          percentage
          price {
            value
            currency {
              id
              code
            }
          }
        }
        picture {
          id
          kind
          fileName
          fileUrl
        }
        copiedPictures {
          id
          quality
          kind
          fileName
          fileUrl
        }
        choices {
          id
          names {
            id
            languageCode
            value
          }
          descriptions {
            id
            languageCode
            value
          }
          minItems
          maxItems
          supplements {
            id
            names {
              id
              languageCode
              value
            }
            descriptions {
              id
              languageCode
              value
            }
            price {
              value
              currency {
                id
                code
              }
            }
            discount {
              percentage
              price {
                value
                currency {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
`;

// CART
export const GET_MENU_CART_DETAILS = gql`
  query GetMenuCartDetails($input: GetMenuCartDetailsInput!) {
    getMenuCartDetails(input: $input) {
      count
      products {
        id
        product {
          id
          names {
            id
            languageCode
            value
          }
          shortDescriptions {
            id
            languageCode
            value
          }
          longDescriptions {
            id
            languageCode
            value
          }
          picture {
            id
            kind
            fileName
            fileUrl
          }
          unit {
            kind
            default
          }
        }
        supplements {
          id
          names {
            id
            languageCode
            value
          }
          quantity
          unitPrice {
            value
            currency {
              id
              code
            }
          }
        }
        supplementsPrice {
          value
          currency {
            code
            id
          }
        }
        notes
        quantity
        unitPrice {
          value
          currency {
            id
            code
          }
        }
        prepTime
        totalPrice {
          value
          currency {
            id
            code
          }
        }
      }
      originalTotalPrice {
        value
        currency {
          id
          code
        }
      }
      totalDiscountedCost {
        value
        currency {
          id
          code
        }
      }
      totalDeliveredCost {
        value
        currency {
          id
          code
        }
      }
      totalPrice {
        value
        currency {
          id
          code
        }
      }
    }
  }
`;

//  get account main menu
export const GET_MAIN_MENU = gql`
  query GetMainMenu($input: GetMainMenuInput!) {
    getMainMenu(input: $input) {
      identifier
    }
  }
`;

// search
export const GET_PRODUCTS = gql`
  query GetProducts($input: GetProductsInput!) {
    getProducts(input: $input) {
      list {
        id
        identifier
        category {
          id
          identifier
          names {
            id
            languageCode
            value
          }
        }
        names {
          id
          languageCode
          value
        }
        shortDescriptions {
          id
          languageCode
          value
        }
        longDescriptions {
          id
          languageCode
          value
        }
        price {
          value
          currency {
            id
            code
          }
        }
        discount {
          percentage
          price {
            value
            currency {
              id
              code
            }
          }
        }
        unit {
          kind
          default
        }
        picture {
          id
          kind
          fileName
          fileUrl
        }
        copiedPictures {
          id
          quality
          kind
          fileName
          fileUrl
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query getAccessMenuCategoryDetail($input: GetAccessMenuCategoryDetailInput!) {
    getAccessMenuCategoryDetail(input: $input) {
      category {
        id
        display
        pictureIsVisible
        identifier
        picture {
          id
          kind
          fileName
          fileUrl
        }
        copiedPictures {
          id
          quality
          kind
          fileName
          fileUrl
        }
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        order
        products {
          id
          identifier
          isAvailable
          names {
            id
            languageCode
            value
          }
          shortDescriptions {
            id
            languageCode
            value
          }
          longDescriptions {
            id
            languageCode
            value
          }
          unit {
            kind
            default
          }
          price {
            value
            currency {
              id
              code
            }
          }
          discount {
            percentage
            price {
              value
              currency {
                id
                code
              }
            }
          }
          picture {
            id
            kind
            fileName
            fileUrl
          }
          copiedPictures {
            id
            quality
            kind
            fileName
            fileUrl
          }
          order
          borderImage
        }
        subCategories {
          id
          identifier
          display
          order
          names {
            id
            languageCode
            value
          }
          products {
            id
            identifier
            isAvailable
            names {
              id
              languageCode
              value
            }
            shortDescriptions {
              id
              languageCode
              value
            }
            longDescriptions {
              id
              languageCode
              value
            }
            unit {
              kind
              default
            }
            price {
              value
              currency {
                id
                code
              }
            }
            discount {
              percentage
              price {
                value
                currency {
                  id
                  code
                }
              }
            }
            picture {
              id
              kind
              fileName
              fileUrl
            }
            copiedPictures {
              id
              quality
              kind
              fileName
              fileUrl
            }
            order
            borderImage
          }
        }
      }
    }
  }
`;

// get widgets
export const GET_WIDGETS = gql`
  query GetCategoryWidgets($input: GetAreaWidgetsInput!) {
    getAreaWidgets(input: $input) {
      widgets {
        id
        kind
        area
        names {
          id
          languageCode
          value
        }
        styles {
          kind
          value
        }
        order
        content {
          textContent {
            id
            languageCode
            value
          }
          media {
            kind
            value
          }
        }
        isVisible
        isDeleted
      }
    }
  }
`;

// get company information
export const GET_COMPANY_INFORMATION = gql`
  query GetCompanyInformations($input: GetCompanyInformationsInput!) {
    getCompanyInformations(input: $input) {
      company {
        id
        website
        address {
          roadName
        }
        socials {
          kind {
            id
            name
          }
          value
        }
      }
    }
  }
`;

// PAYMENT
export const CHECK_MENU_ORDER = gql`
  mutation CheckMenuOrder($input: CheckMenuOrderInput!) {
    checkMenuOrder(input: $input) {
      id
    }
  }
`;

export const GET_STRIPE_PUBLIC_KEY = gql`
  query GetStripePublicKey($input: GetStripePublicKeyInput!) {
    getStripePublicKey(input: $input) {
      publicKey
    }
  }
`;

export const GET_STRIPE_ORDER_AMOUNT = gql`
  query GetStripeOrderAmount($input: GetStripeOrderAmountInput!) {
    getStripeOrderAmount(input: $input) {
      menu
      stripeCustomerId
      amount
      currency
    }
  }
`;

export const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation CreateStripePaymentIntent($input: CreateStripePaymentIntentInput!) {
    createStripePaymentIntent(input: $input) {
      id
      clientSecret
    }
  }
`;
