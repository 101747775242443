/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from "react";
import styled from "styled-components";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { useQuery } from "@apollo/client";
import { MENU_ADS_URL } from "../api/config";
import {
  GetAccessMenuAdsQuery,
  GetAccessMenuAdsQueryVariables,
  AccessMenuAdPosition,
  AccessMenuAdPayload,
} from "../api/types";
import { GETLISTMENUADS } from "../api/query.api";

type CarouselType = {
  clickAd: (ad: AccessMenuAdPayload) => void;
  position: AccessMenuAdPosition;
  className?: string;
  id: string;
};

const MenuCarousel: FC<CarouselType> = ({
  clickAd,
  position,
  id,
  className,
}) => {
  const { data, loading } = useQuery<
    GetAccessMenuAdsQuery,
    GetAccessMenuAdsQueryVariables
  >(GETLISTMENUADS, { variables: { input: { menu: id, position } } });

  const ads = data?.getAccessMenuAds.list;

  if (!ads || !ads.length) return null;
  return (
    <Wrapper className={className}>
      <Carousel
        slidesPerPage={1}
        clickToChange={!!(ads && ads?.length > 1)}
        autoPlay={ads && ads?.length > 1 ? 6000 : undefined}
        animationSpeed={1000}
        infinite={!!(ads && ads?.length > 1)}
        keepDirectionWhenDragging
      >
        {ads?.map((ad, index) => {
          return (
            !loading &&
            ad.pictures?.cover?.kind !== "MENU_AD_VIDEO" && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <img
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => clickAd(ad)}
                className="img__cover"
                src={MENU_ADS_URL! + ad.pictures?.cover?.fileName}
                alt="carousel"
              />
            )
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 375px;
  max-height: 400px;
  margin: 0 auto;
  overflow: hidden;
  .img__cover {
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2);
  }
  @media only screen and (min-width: 700px) {
  }
`;

export default MenuCarousel;
