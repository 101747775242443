export const getFont = (font: string) => {
  switch (font) {
    case "PlayfairDisplay-Regular":
      return "Playfair Display";
    case "MarcelleScript":
      return "Marcelle Script";
    case "BarlowCondensed-Regular":
      return "barlow_condensedregular";
    case "BreeSerif-Regular":
      return "bree_serifregular";
    case "Calistoga-Regular":
      return "calistogaregular";
    case "Caveat-Regular":
      return "caveatregular";
    case "Comfortaa-Regular_Light":
      return "comfortaaregular";
    case "Courgette-Regular":
      return "courgetteregular";
    case "DMSans-Regular":
      return "dm_sansregular";
    case "Exo-Regular":
      return "exoregular";
    case "FredokaOne-Regular":
      return "fredoka_oneregular";
    case "IndieFlower":
      return "indie_flowerregular";
    case "Kalam-Regular":
      return "kalamregular";
    case "Lobster-Regular":
      return "lobsterregular";
    case "Lora-Regular":
      return "loraregular";
    case "Montserrat-Regular":
      return "montserratregular";
    case "Satisfy-Regular":
      return "satisfyregular";
    case "ApercuPro-Bold":
      return "Apercu Pro";
    case "Verveine":
      return "Verveine";
    case "HKConcentratePro":
      return "HKConcentratePro";
  }
};
