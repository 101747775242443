import styled from "styled-components";

import { URL } from "../../api/config";

type SeperatorType = {
  alwaysShow?: boolean;
};

export const Separator = styled.div<SeperatorType>`
  height: 20px;
  width: calc(100vw - 330px);
  @media only screen and (min-width: 930px) {
    height: ${(props) => (props.alwaysShow ? "20px" : "0px")};
    position: sticky;
    top: 0;
    border-top: ${(props) =>
      props.alwaysShow ? "1px solid #7b7b7b82" : "unset"};
    background: rgb(185, 185, 185);
    background: linear-gradient(
      0deg,
      rgba(185, 185, 185, 0) 50%,
      rgba(185, 185, 185, 0.1) 100%
    );
  }
`;

export const MasterContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: "list";
  direction: ${(props) => (props.theme.direction === "RTL" ? "rtl" : "ltr")};
  .language-btn {
    display: none;
  }
  .content {
    position: relative;
    z-index: 9;
    display: grid;
    grid-template-columns: 70px auto 70px;
    display: flex;
    margin: 0px auto 0 !important;
  }
  .content > div {
    grid-column: 2/3;
  }
  #header {
    position: initial;
    display: none;
    grid-column: span 2;
    padding: 0;
  }
  #left {
    height: min-content;
    position: sticky;
    top: 155px;
    display: none;
    padding: 0;
    order: ${(props) => (props.theme.direction === "RTL" ? "2" : "1")};
    .carousel {
      margin-bottom: 25px;
    }
  }
  #right {
    order: ${(props) => (props.theme.direction === "RTL" ? "1" : "2")};
  }
  > div:first-child {
    display: none;
  }

  .cart-mobile {
    @media only screen and (min-width: 930px) {
      display: none;
    }
  }
  @media only screen and (min-width: 930px) {
    grid-template-columns: 320px 1fr;
    grid-template-rows: 100px auto;
    grid-template-areas: "appbar appbar" "categorieList list";
    grid-column-gap: 10px;
    .content {
      margin: 20px auto 0px;
    }
    #header {
      position: sticky;
      top: 0px;
      padding: 0;
      display: block;
      grid-column: span 2;
      .desctop-header {
        ${(props) =>
          props.theme.direction === "RTL"
            ? "margin: 20px 0 0 auto"
            : "margin: 20px 0 0 0"};
        width: 220px;
      }
    }
    .language-btn {
      position: absolute;
      top: 55px;
      display: block;
      ${(props) =>
        props.theme.direction === "RTL"
          ? "left: calc(50vw - 475px)"
          : "right: calc(50vw - 475px)"};
    }
    #left {
      height: calc(100vh - 185px);
      display: block;
      padding-bottom: 30px;
      overflow-y: scroll;
      .carousel {
        display: none;
      }
      .menu {
        grid-template-columns: 1fr !important;
      }
    }

    > div:first-child {
      display: block;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  grid-area: list;
  .carousel--desktop {
    display: none;
  }
  .container {
    height: 100%;
    overflow: hidden;
  }
  .container__products-list {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 0 10px 30px 10px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .with-nav {
    height: calc(100% - 130px);
    top: 130px;
  }
  .without-nav {
    height: calc(100% - 70px);
    top: 70px;
  }
  .category__header {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 8;
    border-bottom: 1px solid #e9e9e9;
    .content {
      position: relative;
      z-index: 9;
      display: grid;
      grid-template-columns: 70px auto 70px;
      grid-template-areas: ${(props) =>
        props.theme.direction === "RTL"
          ? `". header_subtitle button_back"`
          : `"button_back header_subtitle ."`};
      align-items: center;
      justify-items: center;
      margin: 20px auto 0;
      .header__subtitle {
        grid-area: header_subtitle;
      }
      .left {
        grid-area: button_back;
      }
    }
    button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      border: 0;
      border-radius: 50%;
      background: ${({ theme }) =>
        theme.circularButtonBackground
          ? `url(${URL}${theme.circularButtonBackground})`
          : "rgba(127, 127, 127, 0.3)"};
      background-size: contain;
      outline: none;
    }
    #category {
      order: 2;
    }
    .right {
      margin: auto auto auto 10px;
      order: ${(props) => (props.theme.direction === "RTL" ? "1" : "3")};
    }
  }
  .container__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px 10px;
    direction: ${(props) => (props.theme.direction === "RTL" ? "rtl" : "ltr")};
    padding: 10px 0;
  }

  .subcategory-title {
    display: block;
    grid-column: span 2;
    width: 100%;
    margin-top: 15px;
    font-size: 24px;
    color: ${(props) => props.theme.paletteColors.secondary};
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
    text-align: center;
  }
  .color {
    color: ${(props) => props.theme.paletteColors.primary};
  }

  /* SCROLLL */
  .nav {
    height: 60px;
    overflow: hidden;
  }

  .nav__list {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background: ${({ theme }) => theme.colorBackgroundCard || "transparent"};
    li {
      width: max-content;
      display: inline-block;
      padding: 0 15px 15px 15px;
      list-style: none;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      white-space: nowrap;
      color: ${(props) => props.theme.paletteColors.secondary};
      font-size: 18px;
      text-decoration: none;
      font-family: ${(props) =>
        props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
    }
  }

  .nav__list::-webkit-scrollbar {
    display: none;
  }

  .nav__scroll {
    height: 4px;
    position: absolute;
    bottom: 0;
    background: ${(props) => {
      const primaryColor = props.theme.paletteColors.primary;
      if (primaryColor) {
        return `linear-gradient( 90deg,
      ${primaryColor}00 0%,
      ${primaryColor} 30%,
      ${primaryColor} 50%,
      ${primaryColor} 70%,
      ${primaryColor}00 100%
    );`;
      }
      return `linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(2, 0, 36, 1) 30%,
        rgba(2, 0, 36, 1) 50%,
        rgba(2, 0, 36, 1) 70%,
        rgba(2, 0, 36, 0) 100%
      );`;
    }};
  }
  /* END SCROLL */

  .hideSearch {
    right: -60px;
  }

  @media (min-width: 1200px) {
    .category__header {
      max-width: 870px;
    }
  }
  @media (min-width: 930px) and (max-width: 1199px) {
    .category__header {
      max-width: calc(100vw - 330px);
    }
  }

  @media (min-width: 930px) {
    width: 100%;
    height: 100vh;
    position: relative;
    .left {
      opacity: 0;
    }

    .container__products-list {
      height: calc(100vh - 230px);
      width: calc(100vw - 350px);
      top: 210px;
      padding: 0 10px 20px 10px;
      direction: ${(props) =>
        props.theme.direction === "RTL" ? "right" : "left"};
      overflow-y: auto;
    }
    .container__list {
      /* width: calc(100vw - 350px); */
      box-sizing: border-box;
    }
    .category__header {
      top: 80px;
      .content {
        margin: 0 auto;
        padding: 0 10px;
      }
    }
    .with-nav {
      height: calc(100vh - 230px);
      top: 210px;
    }
    .without-nav {
      height: calc(100% - 170px);
      top: 150px;
    }

    .container__grid {
      max-width: 930px;
      grid-template-columns: 31.11% 31.11% 31.11%;
      column-gap: 3.33%;
      grid-template-rows: min-content;
      .subcategory-title {
        grid-column: span 3;
      }
    }
  }
  @media (min-width: 1200px) {
    .container__products-list {
      width: calc(1200px - 330px);
    }
  }
`;
