import React, { FC } from "react";
import styled, { keyframes } from "styled-components";

const Loader: FC = () => {
  return (
    <SvgStyled
      stroke="currentColor"
      fill="none"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12H19C19 15.866 15.866 19 12 19V22Z"
        fill="currentColor"
      />
      <path
        d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
        fill="currentColor"
      />
    </SvgStyled>
  );
};

const rotate360 = keyframes`
from {
    transform: rotate(0deg);
 }
to {
    transform: rotate(360deg);
 }
`;

const SvgStyled = styled.svg`
  font-size: 20px;
  animation: ${rotate360} 0.9s linear infinite;
`;

export default Loader;
