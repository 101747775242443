/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
//  libraries imports
import * as React from "react";
import styled from "styled-components";
import {
  XCircle,
  X,
  ArrowLeft,
  ArrowRight,
  Check,
  DownloadCloud,
} from "react-feather";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { PDFDownloadLink } from "@react-pdf/renderer";
import _ from "lodash";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

//  local imports
import { getCookie, getDomain } from "../utils/util";
import Receipt from "./Receipt";
import emptyCartIcon from "../Images/emptyCart.svg";
import { WS_URL, URL } from "../api/config";
import {
  GET_MENU_CART_DETAILS,
  GETMENU,
  CHECK_MENU_ORDER,
  CREATE_STRIPE_PAYMENT_INTENT,
  GET_MENU_ORDER_STATUS,
} from "../api/query.api";
import {
  CLEAR_MENU_CART,
  CREATE_MENU_ORDER,
  INIT_PAYMENT_SKIPCASH,
} from "../api/mutation.api";
import { translate } from "../utils/dictionary";
import { CartProduct, Loader, Radio, Checkbox } from ".";
import {
  GetMenuCartDetailsQuery,
  CartProductPayload,
  ClearMenuCartMutation,
  ClearMenuCartMutationVariables,
  CreateMenuOrderMutation,
  CreateMenuOrderMutationVariables,
  CustomerInformationsInput,
  GetAccessMenuDetailQuery,
  GetAccessMenuDetailQueryVariables,
  GetMenuCartDetailsQueryVariables,
  LanguageCode,
  CheckMenuOrderMutationVariables,
  CheckMenuOrderMutation,
  CreateStripePaymentIntentMutation,
  CreateStripePaymentIntentMutationVariables,
  InitiatePaymentSkipcashMutationVariables,
  InitiatePaymentSkipcashMutation,
  GetMenuOrderStatusQueryVariables,
  GetMenuOrderStatusResult,
} from "../api/types";

import {
  localDomain,
  localIdentifier,
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../Graphql/apolloClient";

type ProductRecapType = {
  rank: number;
};
type ModalCartProps = {
  show: boolean;
  menu: string;
  data: GetMenuCartDetailsQuery | undefined;
  onClose: () => void;
};

type SeperatorType = {
  height: number;
};

type ContainerType = {
  show: boolean;
};

type Errors = {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  phone?: boolean;
  payment?: boolean;
  terms?: boolean;
};
const ModalCart: React.FC<ModalCartProps> = ({ show, onClose, data, menu }) => {
  const device = getCookie();
  const stripe = useStripe();
  const elements = useElements();
  const domain = React.useMemo(() => getDomain(), []);

  // console.log("domain ", domain);
  // const initialCountryNumber = useMemo(() => {
  //   const domain = getDomain();
  //   if (domain === "menume") return 33;
  //   if (domain === "digipages") return 974;
  //   return 216;
  // }, []);
  // STATES
  const initialCustomer = {
    firstName: "",
    lastName: "",
    email: "",
    phone: { countryCode: 0, number: "" },
  };

  const [cardToken, setCardToken] = React.useState("");
  const [tab, setTab] = React.useState<number>(1);
  const [orderId, setOrderId] = React.useState("");
  const [customer, setCustomer] = React.useState<CustomerInformationsInput>(
    initialCustomer
  );

  const paymentWindow = React.useRef<any>(null);
  const [note, setNote] = React.useState<string>("");
  const [errors, setErrors] = React.useState<Errors>({});
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [stripeStatus, setStripeStatus] = React.useState({
    complete: false,
    error: "",
  });
  const [cardOrder, setCardOrder] = React.useState<number | undefined>(
    undefined
  );
  const [clientSecret, setClientSecret] = React.useState("");
  const [confirmPaymentLoading, setConfirmPaymentLoading] = React.useState(
    false
  );
  const [terms, setTerms] = React.useState(false);

  const { data: localState } = useQuery<GetLocalStateQuery>(GET_LOCAL_STATE);
  const localLanguage = localState?.language || "EN";
  const localPodIsEnabled = localState?.podIsEnabled;
  const localPaymentMethods = localState?.paymentMethods;

  const { data: menuDetails } = useQuery<
    GetAccessMenuDetailQuery,
    GetAccessMenuDetailQueryVariables
  >(GETMENU, {
    variables: {
      input: { domain: localDomain(), identifier: localIdentifier() },
    },
  });

  // const [getOrderAmount] = useLazyQuery<
  //   GetStripeOrderAmountQuery,
  //   GetStripeOrderAmountQueryVariables
  // >(GET_STRIPE_ORDER_AMOUNT, {
  //   variables: {
  //     input: {
  //       device,
  //       menu,
  //     },
  //   },
  //   onCompleted: () => setTab(tab + 1),
  // });
  const [getMenuOrderStatus, getMenuOrderStatusData] = useLazyQuery<
    GetMenuOrderStatusResult,
    GetMenuOrderStatusQueryVariables
  >(GET_MENU_ORDER_STATUS, {
    variables: {
      input: { id: "" },
    },
    onCompleted: () => {
      // if (result.getMenuOrderStatus.status === "PAID") {
      //   setConfirmPaymentLoading(false);
      //   setTab(4);
      // }
      setTab(4);
    },
  });

  // MUTATIONS
  const [emptyCart] = useMutation<
    ClearMenuCartMutation,
    ClearMenuCartMutationVariables
  >(CLEAR_MENU_CART, {
    variables: { input: { device, menu } },
    awaitRefetchQueries: true,
    refetchQueries: () => [
      {
        query: GET_MENU_CART_DETAILS,
        variables: { input: { device, menu } },
      },
    ],
    fetchPolicy: "no-cache",
  });

  const [checkMenuOrder, { loading: checkMenuOrderLoading }] = useMutation<
    CheckMenuOrderMutation,
    CheckMenuOrderMutationVariables
  >(CHECK_MENU_ORDER, {
    variables: {
      input: {
        customerData: {
          firstName: customer.firstName!,
          lastName: customer.lastName!,
          email: customer.email!,
          phone: {
            // countryCode: customer.phone?.countryCode!,
            countryCode: 217,
            // number: customer.phone?.number!,
            number: "099090899",
          },
        },
        device,
        menu,
        paymentMethod,
      },
    },
  });

  const [createStripePayment, { loading: createPaymentLoading }] = useMutation<
    CreateStripePaymentIntentMutation,
    CreateStripePaymentIntentMutationVariables
  >(CREATE_STRIPE_PAYMENT_INTENT, {
    variables: {
      input: {
        device,
        menu,
        order: orderId,
      },
    },
  });

  const getCompanyKind = () => {
    switch (domain) {
      case "menume":
        return "MENUME";
      case "digipages":
        return "DIGIPAGES";
      case "digimenu":
        return "DIGIMENU";
      default:
        return "DIGIPAGES";
    }
  };

  const [
    initSkipcashPay,
    { data: initiatePaymentSkipcash, loading: initiatePaymentSkipcashLoading },
  ] = useMutation<
    InitiatePaymentSkipcashMutation,
    InitiatePaymentSkipcashMutationVariables
  >(INIT_PAYMENT_SKIPCASH, {
    variables: {
      input: {
        device: "",
        orderId: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        menuId: "",
      },
    },
  });

  // console.log(
  //   `payment method: ${
  //     paymentMethod === "CASH" || paymentMethod.length === 0
  //       ? null
  //       : paymentMethod
  //   }`
  // );

  const [
    order,
    { data: createOrder, loading: createOrderLoading },
  ] = useMutation<CreateMenuOrderMutation, CreateMenuOrderMutationVariables>(
    CREATE_MENU_ORDER,
    {
      variables: {
        input: {
          languageCode: localLanguage as LanguageCode,
          device,
          menu,
          customerInformations: customer,
          customerNotes: note,
          kind: "ONLINE",
          paymentMethod:
            paymentMethod === "CASH" || paymentMethod.length === 0
              ? null
              : paymentMethod,
          companyKind: getCompanyKind(),
        },
      },
      onCompleted: ({ createMenuOrder }) => {
        // setTab(4);
        setCardToken("");
        setClientSecret("");
        if (createMenuOrder.id) {
          const socket = new WebSocket(`${WS_URL}`);
          const orderData = {
            event: "new_order",
            id: createMenuOrder.id,
          };
          socket.onopen = () => {
            socket.send(JSON.stringify(orderData));
          };
        }
      },
    }
  );

  const [getCartDetails] = useLazyQuery<
    GetMenuCartDetailsQuery,
    GetMenuCartDetailsQueryVariables
  >(GET_MENU_CART_DETAILS, {
    variables: { input: { device, menu } },
    fetchPolicy: "network-only",
  });

  //  LOCAL VARIABLES
  const cart = data?.getMenuCartDetails;
  const cartPrice = Math.round((cart?.totalPrice?.value || 0) * 100) / 100;

  const language: number = _.findIndex(
    menuDetails?.getAccessMenuDetail.accessMenu?.languages,
    {
      languageCode: localLanguage,
    }
  );

  const options = React.useMemo(
    () => ({
      style: {
        base: {
          height: 50,
          color: "#424770",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  window.onfocus = () => {
    // console.log(
    //   "#current window : focused\nnew window : ",
    //   paymentWindow.current
    // );
    setTimeout(() => {
      if (paymentWindow.current) {
        if (paymentWindow.current.closed) {
          paymentWindow.current = null;
          // console.log(createOrder?.createMenuOrder?.id);

          getMenuOrderStatus({
            variables: {
              input: { id: createOrder?.createMenuOrder?.id || "" },
            },
          });

          // setConfirmPaymentLoading(false);
          // setTab(4);
        }
      }
    }, 2000);
  };

  //  FUNCTIONS
  const checkCustomerInfo = () => {
    const validationErrors: Errors = {};
    if (customer.firstName === undefined || customer.firstName === "") {
      validationErrors.firstName = true;
    }
    if (customer.lastName === undefined || customer.lastName === "") {
      validationErrors.lastName = true;
    }
    if (customer.phone?.number === undefined || customer.phone.number === "") {
      validationErrors.phone = true;
    }
    const emailReg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (customer.email && !emailReg.test(customer.email)) {
      validationErrors.email = true;
    }
    if (
      localPodIsEnabled ||
      (localPaymentMethods && localPaymentMethods.length > 0)
    ) {
      if (!paymentMethod) {
        validationErrors.payment = true;
      } else if (paymentMethod === "STRIPE") {
        if (!customer.email) validationErrors.email = true;

        if (!stripeStatus.complete) {
          validationErrors.payment = true;
        }
      }
    }
    if (domain === "menume" && !terms) {
      validationErrors.terms = true;
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (paymentMethod && paymentMethod === "STRIPE") {
        checkMenuOrder().then((response) => {
          setOrderId(response.data?.checkMenuOrder.id || "");
          // getOrderAmount();
          createStripePayment().then(async ({ data: createPaymentData }) => {
            const card = elements?.getElement(CardElement);
            const secret =
              createPaymentData?.createStripePaymentIntent?.clientSecret;
            if (stripe && card && secret) {
              setClientSecret(secret);
              const payload = await stripe.createToken(card);
              // const payload = await stripe.createPaymentMethod({
              //   type: "card",
              //   card,
              // });
              if (payload?.token) {
                setCardToken(payload.token.id);
                setTab(tab + 1);
              }
            }
          });
        });
      } else {
        setTab(tab + 1);
      }
    }
  };

  const close = () => {
    if (tab === 4) {
      setCustomer(initialCustomer);
      getCartDetails();
      setTab(1);
      setNote("");
    }
    setErrors({});
    onClose();
  };

  const handleNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setCustomer({ ...customer, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  // const handleCustomerPhone = (
  //   eventValue: string,
  //   field: "number" | "countryCode"
  // ) => {
  //   let value: string | number = eventValue;
  //   if (field === "countryCode") value = parseInt(eventValue, 10);

  //   setCustomer((prevCustomer) => ({
  //     ...prevCustomer,
  //     phone: { ...prevCustomer.phone, [field]: value },
  //   }));
  //   setErrors({ ...errors, phone: false });
  // };

  // const handleCustomerAddress = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value, name } = event.target;
  //   setCustomer({
  //     ...customer,
  //     address: { roadNames: [{ value, languageCode: "EN" }] },
  //   });
  //   setErrors({ ...errors, [name]: false });
  // };

  const handleCustomerPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numberRegex = /^[0-9]{1,12}$/;

    if (numberRegex.test(value)) {
      setCustomer({
        ...customer,
        phone: { ...customer.phone, number: value },
      });
      setErrors({ ...errors, phone: false });
    }
  };

  const handleChangeCard = (event: any) => {
    setStripeStatus({
      complete: event.complete,
      error: event.error,
    });
    setErrors({ ...errors, payment: false });
  };

  const getProductUnit = (quantity: number, kind: string) => {
    switch (kind) {
      case "KILOGRAM":
        return `${quantity}kg`;
      case "METER":
        return `${quantity}m`;
      case "GRAM":
        return `${quantity * 100}g`;
      default:
        return `${quantity}`;
    }
  };

  const handleNext = async () => {
    // console.log(paymentMethod);

    if (
      tab === 3 &&
      paymentMethod !== "STRIPE" &&
      paymentMethod !== "SKIPCASH"
    ) {
      order().then(() => {
        setTab(4);
      });
    }

    if (tab === 3 && paymentMethod === "SKIPCASH") {
      setConfirmPaymentLoading(true);
      order().then((res) => {
        // console.log(res.data?.createMenuOrder.id);
        initSkipcashPay({
          variables: {
            input: {
              device,
              orderId: res.data?.createMenuOrder.id!,
              firstName: customer.firstName!,
              lastName: customer.lastName!,
              phone: `+${customer.phone?.countryCode}${customer.phone?.number}`,
              email: customer.email!,
              menuId: menu!,
            },
          },
        })
          .then((val) => {
            // console.log(val.data?.initiatePaymentSkipcash.payUrl);
            // setTab(4);
            paymentWindow.current = window.open(
              val.data?.initiatePaymentSkipcash.payUrl,
              "_blank"
            );
            paymentWindow.current.focus();
            // setPaymentWindowOpen(true);
            // console.log(paymentWindow.current);
          })
          .catch((err) => {
            setErrors({ ...errors, payment: err });
          });
      });
    }

    if (tab === 3 && paymentMethod === "STRIPE") {
      if (stripe && cardToken) {
        setConfirmPaymentLoading(true);
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: { token: cardToken },
          },
        });
        if (payload?.error) {
          console.log(payload.error.message);
        } else {
          order({
            variables: {
              input: {
                id: orderId,
                languageCode: localLanguage as LanguageCode,
                device,
                menu,
                customerInformations: customer,
                customerNotes: note,
                kind: "ONLINE",
                companyKind: getCompanyKind(),
                paymentMethod,
              },
            },
          }).then(() => {
            setConfirmPaymentLoading(false);
            setTab(4);
          });
        }
      }
    }

    if (tab < 3) {
      setTab(tab + 1);
    }
  };

  const handleBack = () => {
    if (tab === 1) close();
    if (tab > 1) setTab(tab - 1);
    if (tab === 2) {
      setCustomer(initialCustomer);
      setErrors({});
      setNote("");
      setPaymentMethod("");
    }
  };

  const getCartHeader = () => {
    switch (tab) {
      case 1:
        return translate(localLanguage, 13);
      case 2:
        return translate(localLanguage, 14);
      case 3:
        return translate(localLanguage, 15);
      default:
        return translate(localLanguage, 13);
    }
  };
  // TODO: to fix in with proper translate system
  const getTermsText = React.useMemo(() => {
    switch (localLanguage) {
      case "FR":
        return (
          <span>
            Je reconnais avoir pris connaissance et accepté&nbsp;
            <a
              href="https://menume.io/wp-content/uploads/conditions%20_terme_generale.pdf"
              target="_blank"
              rel="noreferrer"
            >
              les conditions générales de vente
            </a>
          </span>
        );
      case "AR":
        return (
          <span>
            أقر بقراءة وقبول&nbsp;
            <a
              href="https://menume.io/wp-content/uploads/conditions%20_terme_generale.pdf"
              target="_blank"
              rel="noreferrer"
            >
              الشروط العامة
            </a>
            &nbsp; للبيع
          </span>
        );
      default:
        return (
          <span>
            I declare having familiarized with Digipages&nbsp;
            <a
              href="https://menume.io/wp-content/uploads/conditions%20_terme_generale.pdf"
              target="_blank"
              rel="noreferrer"
            >
              general terms and conditions.
            </a>
          </span>
        );
    }
  }, [localLanguage]);
  // eslint-disable-next-line consistent-return
  const getTabContent = () => {
    switch (tab) {
      case 1:
        return (
          <div className="content-wrapper">
            {cart?.products && cart?.products.length > 0 ? (
              <>
                <div className="products-list">
                  {cart?.products?.map((product: CartProductPayload) => {
                    return (
                      <CartProduct
                        key={product.id}
                        data={product}
                        menu={menu || ""}
                      />
                    );
                  })}
                </div>
                <div className="price-total">
                  <label htmlFor="price">
                    {cart?.totalDeliveredCost?.value
                      ? translate(localLanguage, 19)
                      : ""}
                  </label>
                  <label htmlFor="currency">
                    {cart?.totalDeliveredCost?.value
                      ? `${
                          Math.round(
                            (cart?.totalDeliveredCost?.value || 0) * 100
                          ) / 100
                        } ${cart?.totalDeliveredCost?.currency?.code}`
                      : ""}
                  </label>
                  <Hr />
                  <label htmlFor="language">
                    {translate(localLanguage, 18)}
                  </label>
                  <label htmlFor="price">
                    {`${cartPrice} ${cart?.totalPrice?.currency?.code}`}
                  </label>
                </div>
              </>
            ) : (
              <div className="empty-state">
                <img src={emptyCartIcon} alt="empty cart" />
                <h4>{translate(localLanguage, 11)}</h4>
                <p>{translate(localLanguage, 12)}</p>
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className="content-wrapper">
            <div>
              <label htmlFor="a" className="info-form-label">
                {translate(localLanguage, 21)}
              </label>
              <div className="form">
                <div className="customer-info">
                  <input
                    type="text"
                    name="firstName"
                    placeholder={
                      errors.firstName
                        ? translate(localLanguage, 27)
                        : translate(localLanguage, 22)
                    }
                    className={`textfield name ${
                      errors.firstName ? "required" : ""
                    }`}
                    value={customer.firstName || ""}
                    onChange={handleCustomer}
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder={
                      errors.lastName
                        ? translate(localLanguage, 27)
                        : translate(localLanguage, 23)
                    }
                    className={`textfield lastname ${
                      errors.lastName ? "required" : ""
                    }`}
                    value={customer.lastName || ""}
                    onChange={handleCustomer}
                  />
                </div>
                {/* <InputPhone
                placeholder={
                  errors.phone
                    ? translate(localLanguage, 27)
                    : translate(localLanguage, 24)
                }
                required={errors.phone}
                value={customer?.phone?.number?.toString() || ""}
                onChange={(e) => handleCustomerPhone(e.target.value, "number")}
                countryInputProps={{
                  value: customer?.phone?.countryCode?.toString(),
                  disableDropdown: true,
                  disableCountryCode: true,
                  onChange: (e) => handleCustomerPhone(e, "countryCode"),
                }}
              /> */}
                <input
                  type="tel"
                  name="phone"
                  value={customer?.phone?.number || ""}
                  placeholder={
                    errors.phone
                      ? translate(localLanguage, 27)
                      : translate(localLanguage, 24)
                  }
                  className={`textfield  ${errors.phone ? "required" : ""}`}
                  pattern="[0-9]"
                  onChange={handleCustomerPhone}
                />

                <input
                  type="text"
                  name="email"
                  value={customer?.email || ""}
                  placeholder={
                    errors.email
                      ? translate(localLanguage, 27)
                      : translate(localLanguage, 25)
                  }
                  className={`textfield ${errors.email ? "required" : ""}`}
                  onChange={handleCustomer}
                />
                {/* <input
                type="text"
                name="address"
                value={customer?.address?.roadNames?.[0]?.value || ""}
                placeholder={
                  errors.email
                    ? translate(localLanguage, 27)
                    : translate(localLanguage, 44)
                }
                className="textfield"
                onChange={handleCustomerAddress}
              /> */}
                <textarea
                  name="note"
                  id="note"
                  placeholder={translate(localLanguage, 26)}
                  rows={3}
                  value={note}
                  onChange={handleNote}
                />
              </div>
              {(localPodIsEnabled ||
                (localPaymentMethods && localPaymentMethods.length > 0)) && (
                <div className="payment-form">
                  <span
                    className={`info-form-label ${
                      errors.payment ? "payment-error" : ""
                    }`}
                  >
                    {translate(localLanguage, 46)}
                  </span>
                  <Radio
                    id="payOnDelivery"
                    label={translate(localLanguage, 47)}
                    name="payment"
                    onChange={() => handleOnChangePayment("CASH")}
                    disabled={!localPodIsEnabled}
                  />
                  {localPaymentMethods?.map((method, index) => (
                    <Radio
                      key={method}
                      id={method}
                      label={
                        method === "STRIPE"
                          ? translate(localLanguage, 48)
                          : translate(localLanguage, 51)
                      }
                      value={method}
                      name="payment"
                      onChange={() => handleOnChangePayment(method, index + 1)}
                    />
                  ))}
                  {cardOrder && paymentMethod === "STRIPE" && (
                    <CardPaymentStyled
                      order={cardOrder}
                      options={options}
                      onChange={handleChangeCard}
                      error={errors.payment}
                    />
                  )}
                </div>
              )}
              {domain === "menume" && (
                <>
                  <div className="terms-row">
                    <Checkbox
                      id="terms"
                      label={getTermsText}
                      name="terms"
                      checked={terms}
                      onChange={() => {
                        setTerms((prevTerm) => !prevTerm);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          terms: false,
                        }));
                      }}
                      error={errors.terms}
                    />
                    {errors.terms && (
                      <span className="terms-error">
                        {translate(localLanguage, 49)}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
            <Hr />
          </div>
        );
      case 3:
        return (
          <div
            className="recap content-wrapper"
            style={{ overflowY: "scroll" }}
          >
            <label htmlFor="a" className="recap-label">
              {translate(localLanguage, 28)}
            </label>
            <div className="repac_info">
              <span>{translate(localLanguage, 30)}</span>
              <span>{`${customer.firstName} ${customer.lastName}`}</span>
              <span>{translate(localLanguage, 24)}</span>
              <span>{customer.phone?.number}</span>
              <span>{translate(localLanguage, 25)}</span>
              <span>{customer.email}</span>
              <span>{translate(localLanguage, 44)}</span>
              <span>{customer.address?.roadNames?.[0]?.value}</span>
              <span>{translate(localLanguage, 26)}</span>
              <p>{note}</p>
            </div>
            {cart?.totalDeliveredCost?.value ? (
              <label htmlFor="a" className="recap-label">
                {translate(localLanguage, 29)}
              </label>
            ) : (
              ""
            )}
            <Seperator height={10} />
            <div>
              <div className="recap_products">
                {cart?.products?.map((product, index) => {
                  return (
                    <ProductRecap rank={1} key={index}>
                      <label htmlFor="a">
                        {product.product?.names?.[
                          product.product?.names?.findIndex(
                            (name) => name.languageCode === localLanguage
                          )
                        ]?.value || ""}
                        {product.supplements?.map((suppelment) => (
                          <span className="supplement">
                            +
                            {suppelment.names?.find(
                              (name) => name.languageCode === localLanguage
                            )?.value || ""}{" "}
                          </span>
                        ))}
                      </label>
                      <div className="product-pq">
                        <span>
                          {`${
                            Math.round((product.unitPrice?.value || 0) * 100) /
                            100
                          } ${product.unitPrice?.currency?.code}`}
                        </span>
                        {product.quantity && product.product?.unit?.kind && (
                          <div>
                            <hr />
                            <span>
                              {getProductUnit(
                                product.quantity,
                                product.product?.unit?.kind
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </ProductRecap>
                  );
                })}
              </div>
              <Seperator height={10} />
              <div className="price-total">
                <label htmlFor="a">
                  {cart?.totalDeliveredCost?.value
                    ? translate(localLanguage, 19)
                    : ""}
                </label>
                <label htmlFor="a">
                  {cart?.totalDeliveredCost?.value
                    ? `${cart?.totalDeliveredCost?.value} ${cart?.totalDeliveredCost?.currency?.code}`
                    : ""}
                </label>

                <Hr />
                <label htmlFor="a">{translate(localLanguage, 18)}</label>
                <label htmlFor="a">
                  {`${cartPrice} ${cart?.totalPrice?.currency?.code}`}
                </label>
              </div>
            </div>
          </div>
        );
      case 4:
        return paymentMethod === "STRIPE" ? (
          <div className="success-tab">
            <div className="success-badge">
              <Check color="#4CD964" size="48" strokeWidth="3" />
            </div>
            <label htmlFor="a" className="success-message">
              {translate(localLanguage, 34)}
            </label>
            <label htmlFor="a" className="success-info">
              {translate(localLanguage, 35)}
            </label>

            {createOrderLoading ? (
              <h5 className="loading-refrence">XXXXXXXX</h5>
            ) : (
              <h5 className="success-code">
                {createOrder?.createMenuOrder.reference}
              </h5>
            )}

            <PDFDownloadLink
              document={
                <Receipt
                  language={language}
                  products={cart?.products}
                  customer={customer}
                  Receiptrefrence={createOrder?.createMenuOrder.reference || ""}
                  delivery={
                    cart?.totalDeliveredCost?.value
                      ? `${cart.totalDeliveredCost.value} ${cart.totalDeliveredCost.currency?.code}`
                      : ""
                  }
                  total={`${cartPrice} ${cart?.totalPrice?.currency?.code}`}
                  note={note}
                />
              }
              style={{
                backgroundColor: "#000000",
                color: "#ffffff",
                margin: "8px 8px 8px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 15px",
                height: "45px",
                borderRadius: "10px",
                textDecoration: "none",
                marginTop: "25px",
                marginBottom: "25px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              fileName="order.pdf"
            >
              {({ loading }) =>
                loading ? (
                  "Loading"
                ) : (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DownloadCloud
                      color="#ffffff"
                      size="18"
                      strokeWidth="2"
                      style={{ marginRight: "10px" }}
                    />
                    {translate(localLanguage, 33)}
                  </span>
                )
              }
            </PDFDownloadLink>
          </div>
        ) : (
          <div className="success-tab">
            <div className="success-badge">
              {getMenuOrderStatusData.data?.getMenuOrderStatus.status ===
                "PAID" || paymentMethod !== "SKIPCASH" ? (
                <Check color="#4CD964" size="48" strokeWidth="3" />
              ) : (
                <XCircle color="#c4141b" size="48" strokeWidth="3" />
              )}
            </div>
            <label
              htmlFor="a"
              className={
                getMenuOrderStatusData.data?.getMenuOrderStatus.status ===
                  "PAID" || paymentMethod !== "SKIPCASH"
                  ? "success-message"
                  : "failed-message"
              }
            >
              {getMenuOrderStatusData.data?.getMenuOrderStatus.status ===
                "PAID" || paymentMethod !== "SKIPCASH"
                ? translate(
                    localLanguage,
                    paymentMethod === "SKIPCASH" ? 52 : 34
                  )
                : translate(localLanguage, 53)}
            </label>
            <button
              type="button"
              style={{
                padding: "0",
                borderRadius: "12px",
                border: "0",
                fontFamily: "Cairo",
                fontSize: "15px",
              }}
              onClick={() => {
                close();
              }}
            >
              close
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const handleOnChangePayment = (payment: string, index?: number) => {
    setPaymentMethod(payment);
    setCardOrder(index);
    setErrors({ ...errors, payment: false });
  };

  return (
    <Wrapper show={show}>
      <div
        aria-label="button"
        onClick={close}
        onKeyDown={close}
        className="modal-overlay"
        role="button"
        tabIndex={0}
      />
      <div className="modal-content">
        {tab !== 4 && (
          <header>
            <h5>{getCartHeader()}</h5>
          </header>
        )}
        <button type="button" className="close" onClick={close}>
          <X color="#f6f6f6" />
        </button>
        {/* <div className="max-container">{getTabContent()}</div> */}
        {getTabContent()}
        {cart?.products && cart?.products.length > 0 && (
          <footer>
            {tab < 4 && (
              <button type="button" className="back" onClick={handleBack}>
                {tab === 1 ? (
                  translate(localLanguage, 45)
                ) : (
                  <>
                    <ArrowLeft size="20" color="#000000" />{" "}
                    {translate(localLanguage, 17)}
                  </>
                )}
              </button>
            )}
            {tab === 1 ? (
              <button
                type="button"
                className="cancel"
                onClick={() => emptyCart()}
              >
                {translate(localLanguage, 31)}
              </button>
            ) : (
              <i style={{ order: 2 }} />
            )}
            {tab === 2 ? (
              <button
                type="button"
                className="forward"
                onClick={checkCustomerInfo}
                disabled={
                  createOrderLoading ||
                  createPaymentLoading ||
                  checkMenuOrderLoading ||
                  confirmPaymentLoading
                }
              >
                {(createOrderLoading ||
                  createPaymentLoading ||
                  checkMenuOrderLoading ||
                  confirmPaymentLoading) && <Loader />}
                {translate(localLanguage, 16)}
                <ArrowRight size="20" color="#ffffff" />
              </button>
            ) : (
              <button
                type="button"
                className={tab === 4 ? "forward save" : "forward"}
                onClick={handleNext}
                disabled={
                  createOrderLoading ||
                  createPaymentLoading ||
                  checkMenuOrderLoading ||
                  confirmPaymentLoading
                }
              >
                {(createOrderLoading ||
                  createPaymentLoading ||
                  checkMenuOrderLoading ||
                  confirmPaymentLoading) && <Loader />}
                {tab < 3 && translate(localLanguage, 16)}
                {tab === 3 && translate(localLanguage, 32)}
                {tab < 3 && <ArrowRight size="20" color="#ffffff" />}
              </button>
            )}
          </footer>
        )}
      </div>
    </Wrapper>
  );
};

const ProductRecap = styled.div<ProductRecapType>`
  display: grid;
  grid-template-columns: 65% 35%;
  ${(props) =>
    props.rank !== 0 && "border-top: 1px solid rgba(219, 216, 213, 0.3);"}
  margin: 0 15px;
  /* ${(props) => props.theme.direction === "RTL" && "order: 2;"}; */
  label {
    /* ${(props) =>
      props.theme.direction === "RTL" && "order: 2;text-align: right;"}; */
    margin: auto 0;
    .supplement {
      color: #8b8a8e;
    }
  }
  .product-pq {
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 7px;
    padding: 5px 15px;
    margin: ${(props) =>
      props.theme.direction === "RTL" ? "10px auto 8px 0" : "10px 0 8px auto"};
    background-color: #f0f0f4;
    border-radius: 20px;
    hr {
      width: 1px;
      display: inline-block;
      height: 11px;
      border: 0;
      border-left: 2px solid #b5b5b5;
      margin: auto 7px auto auto;
    }
  }
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0;
  border: 0;
  border-bottom: 1px solid rgba(219, 216, 213, 0.3);
  order: 3;
`;

const Seperator = styled.div<SeperatorType>`
  width: 100%;
  height: ${(props) => props.height}px;
`;

const Wrapper = styled.div<ContainerType>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: ${(props) => (props.show === true ? "1000" : "-1")};
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-family: Cairo, Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  opacity: ${(props) => (props.show === true ? "1" : "0")};
  pointer-events: all;
  .modal-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: ${(props) =>
      props.show === true ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)"};
    transition: all 0.3s cubic-bezier(0.18, 1.04, 0.7, 1);
  }
  .modal-content {
    width: 95%;
    max-width: 500px;
    height: 100vh;
    max-height: calc(100vh - 80px);
    position: fixed;
    right: 2.5vw;
    bottom: ${(props) => (props.show === true ? "2.5vw" : "-100vh")};
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 20px;
    transition: all 0.3s cubic-bezier(0.18, 1.04, 0.7, 1);
    overflow: hidden;
  }
  .products-list {
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: unset;
      width: 8px;
    }
  }
  button.close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: ${({ theme }) =>
      theme.circularButtonBackground
        ? `url(${URL}${theme.circularButtonBackground})`
        : "rgba(127, 127, 127, 0.3)"};
    background-size: contain;
    border: 0;
    border-radius: 50%;
    outline: none;

    svg {
      stroke: #000000;
    }
  }
  header {
    height: 60px;
    display: flex;
    justify-content: center;
    background-color: #f4f4f4;
    align-items: center;
    /* flex-direction: ${(props) =>
      props.theme.direction === "RTL" ? "row-reverse" : "row"}; */
  }
  h5 {
    color: #9a9ea9;
    font-size: 18px;
    font-family: "Cairo";
    font-weight: normal;
    /* text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"}; */
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .quatity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 25px;
    font-family: Cairo, Cambria, Cochin, Georgia, Times, "Times New Roman",
      serif;
  }
  footer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
    background: white;
    button {
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: 12px;
      border: 0;
      font-family: "Cairo";
      font-size: 15px;
    }
    .back {
      display: flex;
      /* ${(props) =>
        props.theme.direction === "RTL" && "flex-direction: row-reverse;"} */
      justify-content: center;
      align-items: center;
      margin: ${(props) =>
        props.theme.direction === "RTL" ? "8px 8px 8px 0" : "8px 0px 8px 8px"};
      background-color: rgba(0, 0, 0, 0.06);
      color: #000000;
      order: 1;
      svg {
        margin: ${(props) =>
          props.theme.direction === "RTL" ? "0 0 0 8px" : "0 8px 0 0"};
        ${(props) =>
          props.theme.direction === "RTL" && "transform: rotate(180deg);"}
      }
    }
    .disabled {
      opacity: 0.5;
    }
    .forward {
      display: flex;
      /* ${(props) =>
        props.theme.direction === "RTL" && "flex-direction: row-reverse;"} */
      justify-content: center;
      align-items: center;
      margin: ${(props) =>
        props.theme.direction === "RTL" ? "8px 0px 8px 8px" : "8px 8px 8px 0"};
      background-color: #000000;
      color: #ffffff;
      order: 3;
      svg {
        margin: ${(props) =>
          props.theme.direction === "RTL" ? "0 8px 0 0" : "0 0 0 8px"};
        ${(props) =>
          props.theme.direction === "RTL" && "transform: rotate(180deg);"}
      }
    }
    .save {
      display: none;
    }
    .cancel {
      margin: 8px 0;
      background-color: rgba(251, 32, 32, 0.06);
      color: #fb2020;
      order: 2;
    }
  }
  .price-total {
    display: grid;
    grid-template-columns: 1fr 80px;
    padding: 0 15px;
    border-top: 1px solid rgba(219, 216, 213, 0.3);
    border-bottom: 1px solid rgba(219, 216, 213, 0.3);
    background-color: rgba(247, 246, 246, 0.5);
    font-family: Cairo, Cambria, Cochin, Georgia, Times, "Times New Roman",
      serif;
    hr {
      grid-column: span 2;
    }
    label {
      padding: 8px 0;
    }
    label:nth-child(1) {
      color: #8b8a8e;
    }
    label:nth-child(2) {
      color: #8b8a8e;
    }
    label:nth-child(4) {
      color: #42424a;
    }
    label:nth-child(5) {
      color: #42424a;
      font-weight: 700;
    }
  }
  label.info-form-label {
    display: block;
    margin: 15px 15px 0 15px;
    color: #42424a;
    /* text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"}; */
    font-family: "Cairo";
  }
  .form {
    display: grid;
    grid-gap: 15px;
    padding: 15px;
    .customer-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;
    }
    /* .name {
      order: ${(props) => (props.theme.direction === "RTL" ? 2 : 1)};
    }
    .lastname {
      order: ${(props) => (props.theme.direction === "RTL" ? 1 : 2)};
    } */
    .textfield {
      width: 100%;
      height: 50px;
      /* text-align: ${(props) =>
        props.theme.direction === "RTL" ? "right" : "left"}; */
      padding: 0 15px;
      border: 0.5px solid #dadada;
      border-radius: 8px;
      font-family: "Cairo";
      font-size: 16px;
      box-sizing: border-box;
      box-shadow: none;
      outline: 0;
      transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
      :focus {
        width: 100%;
        border: 0.5px solid #73b6ff;
        box-sizing: border-box;
        box-shadow: 0px 0px 6px 2px rgba(33, 150, 243, 0.4);
      }
    }
    textarea {
      padding: 15px;
      border: 0.5px solid #dadada;
      border-radius: 8px;
      outline: 0;
      resize: none;
      font-size: 16px;
      font-family: "Cairo";
      /* text-align: ${(props) =>
        props.theme.direction === "RTL" ? "right" : "left"}; */
      :focus {
        border: 0.5px solid #73b6ff;
        box-sizing: border-box;
        box-shadow: 0px 0px 6px 2px rgba(33, 150, 243, 0.4);
      }
    }
  }
  .recap {
    overflow-y: scroll;
    label.recap-label {
      display: block;
      margin: 10px 15px 0 15px;
      font-family: "Cairo";
      color: #212121;
      font-size: 18px;
      font-weight: 700;
      /* ${(props) =>
        props.theme.direction === "RTL" && "text-align: right;"}; */
    }
    .repac_info {
      display: grid;
      grid-template-columns: 140px 1fr;
      grid-gap: 5px;
      margin-bottom: 20px;
      padding: 0px 15px;
      span,
      p {
        color: #444444;
        font-size: 15px;
        margin: 0;
        /* ${(props) =>
          props.theme.direction === "RTL" && "text-align: right;"} */
      }
      p {
        grid-column: span 2;
        /* ${(props) =>
          props.theme.direction === "RTL" && "order: 8;text-align: right;"} */
      }
      /* span:nth-child(1) {
        ${(props) =>
        props.theme.direction === "RTL" && "order: 2;text-align: right;"}
      }
      span:nth-child(2) {
        ${(props) =>
        props.theme.direction === "RTL" && "order: 1;text-align: right;"}
      }
      span:nth-child(3) {
        ${(props) =>
        props.theme.direction === "RTL" && "order: 4;text-align: right;"}
      }
      span:nth-child(4) {
        ${(props) =>
        props.theme.direction === "RTL" && "order: 3;text-align: right;"}
      }
      span:nth-child(5) {
        ${(props) =>
        props.theme.direction === "RTL" && "order: 6;text-align: right;"}
      }
      span:nth-child(6) {
        ${(props) =>
        props.theme.direction === "RTL" && "order: 5;text-align: right;"}
      } */
      span:nth-child(9) {
        grid-column: span 2;
        /* ${(props) =>
          props.theme.direction === "RTL" && "order: 7;text-align: right;"} */
      }
    }
    .recap_products {
      border-top: 1px solid rgba(219, 216, 213, 0.3);
      border-bottom: 1px solid rgba(219, 216, 213, 0.3);
      background: rgba(247, 246, 246, 0.5);
    }
  }
  .failed-message {
    margin: 10px 0;
    text-align: center;
    line-height: 22px;
    color: #c4141b;
    font-weight: 600;
  }
  .success-tab {
    width: 60%;
    display: grid;
    margin: auto;
    .success-badge {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;
      background-color: rgba(76, 217, 100, 0.14);
      border-radius: 50%;
    }
    .success-message {
      margin: 10px 0;
      text-align: center;
      line-height: 22px;
      color: #4cd964;
      font-weight: 600;
    }
    .success-info {
      margin: 10px 0;
      text-align: center;
      line-height: 18px;
      font-size: 14px;
      font-weight: 100;
      color: #9a9ea9;
    }
    .success-code,
    .loading-refrence {
      margin: 0 auto 10px;
      font-size: 22px;
      font-weight: 700;
    }
    .loading-refrence {
      color: rgba(154, 158, 169, 0.33);
    }
  }
  .empty-state {
    img {
      width: 50%;
      margin: 25% 25% 0 25%;
    }
    h4 {
      text-transform: uppercase;
      margin: 15px 0;
      text-align: center;
    }
    p {
      font-size: 14px;
      color: #9a9ea9;
      margin: 0 50px 25%;
      text-align: center;
      line-height: 18px;
    }
  }
  .required {
    border: 0.5px solid #ff7f7f !important;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 6px 2px rgba(255, 127, 127, 0.4) !important;
    width: 100% !important;
  }
  .textfield::placeholder {
    font-size: 14px;
  }
  .textfield.required::placeholder {
    color: #ff7f7f;
  }
  .max-container {
    max-height: 490px;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
  }
  .content-wrapper {
    height: calc(100vh - 80px);
    min-height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      display: unset;
      width: 8px;
    }
  }
  .payment-form {
    padding: 0 15px;
    .payment-error {
      color: #fb2020;
    }
  }
  .terms-row {
    padding: 10px 15px;
  }
  .terms-error {
    color: #fb2020;
    font-size: 14px;
  }

  @media only screen and (min-width: 930px) {
    .modal-content {
      max-height: 660px;
    }
    .products-list {
      flex: 1;
      overflow-y: auto;
    }
  }
  @media only screen and (min-width: 1200px) {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin-left: calc(50vw - 600px);
    ${(props) => !props.show && "display: none;"}
    .modal-overlay {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .modal-content {
      width: 55vh;
      height: 80vh;
      min-width: 480px;
      max-height: 660px;
      ${(props) =>
        props.theme.direction === "RTL"
          ? "left: calc(50vw - 570px);"
          : "right: calc(50vw - 570px);"}
      overflow-y: scroll;
    }
    .products-list {
      overflow-y: scroll;
    }
    .max-container {
      max-height: unset;
    }
  }
`;

type CardPaymentStyledProps = {
  order: number;
  error?: boolean;
};

const CardPaymentStyled = styled(CardElement)<CardPaymentStyledProps>`
  padding: 12px 0 13px 5px;
  border: ${({ error }) => (error ? "1px solid#ff7f7f" : "1px solid #dadada")};
  box-shadow: ${({ error }) =>
    error ? "0px 0px 6px 2px rgba(255,127,127,0.4)" : "none"};
  border-radius: 5px;
  order: ${({ order }) => order};
`;
export default ModalCart;
