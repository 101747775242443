import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

type InputPhoneProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  msgError?: string;
  dataTestId?: string;
  required?: boolean;
  countryInputProps: PhoneInputProps;
};

const InputPhone = React.forwardRef<HTMLInputElement, InputPhoneProps>(
  (
    { label, msgError, required, countryInputProps, onChange, ...restProps },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const numberRegex = /^[0-9]{1,12}$/;
      const { value } = e.target;
      if (value && !numberRegex.test(value)) return;
      if (onChange) {
        onChange(e);
      }
    };

    return (
      <Wrapper required={required}>
        <PhoneInput
          {...countryInputProps}
          inputProps={{
            disabled: true,
            className: "input__phone",
            ...countryInputProps.inputProps,
          }}
        />
        <input
          ref={ref}
          className="mainInput"
          max="12"
          {...restProps}
          onChange={handleChange}
        />
      </Wrapper>
    );
  }
);

const Wrapper = styled.div<{ required?: boolean }>`
  position: relative;
  width: 100%;
  .label {
    margin-left: 35px;
  }
  .mainInput {
    height: 50px;
    width: 100%;
    overflow: "hidden";
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
    padding: 0 15px 0 82px;
    border: ${({ required }) =>
      required ? "0.5px solid #ff7f7f" : "0.5px solid #dadada"};
    border-radius: 8px;
    font-family: "Cairo";
    font-size: 16px;
    box-sizing: border-box;
    box-shadow: none;
    outline: 0;
    transition: ${({ required }) =>
      required
        ? "0px 0px 6px 2px rgba(255, 127, 127, 0.4)"
        : "box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out"};
    &:focus {
      border: 0.5px solid #73b6ff;
      box-shadow: 0px 0px 6px 2px rgba(33, 150, 243, 0.4);
    }
    &::placeholder {
      ${({ required }) => required && "color: #ff7f7f"};
      font-size: 14px;
    }
  }

  .react-tel-input {
    position: absolute;
    bottom: top;
    left: 0;
    height: 100%;
    z-index: 1;
    width: 102px;
    height: 50px;
    .flag-dropdown {
      border: transparent;
      padding: 2px;
      background-clip: content-box;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
    .input__phone {
      width: 100%;
      height: 100%;
      padding-left: 44px;
      border: none;
      background: transparent;
      font-family: "Cairo";
      font-size: 16px;
      cursor: unset !important;
    }
  }
`;

export default InputPhone;
