import React, { FC } from "react";
import styled from "styled-components";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";

// eslint-disable-next-line import/no-cycle
import { Category, Home, Menu, Contact, Search, StayWithUs } from "..";

const Layout: FC = () => {
  const { pathname } = useLocation();
  return (
    <LayoutStyled>
      <Switch>
        <Redirect from="/:id*(/+)" to={pathname.slice(0, -1)} />
        <Redirect from="/:id/menu*(/+)" to={pathname.slice(0, -1)} />
        <Route path="/:id" component={Home} exact />
        <Route path="/:id/staywithus" component={StayWithUs} />
        <Route path="/:id/contact" component={Contact} />
        <Route path="/:id/menu/search" component={Search} />
        <Route path="/:id/menu" component={Menu} exact />
        <Route path="/:id/menu/:category" component={Category} />
      </Switch>
      <BackgroundStyle />
    </LayoutStyled>
  );
};

const BackgroundStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${(props) =>
    `${props.theme.colorBackground} ` +
    `url(${props.theme.imageBackground}) no-repeat top center`};
  background-size: cover;
  position: fixed;
  top: 0;
  z-index: -1;
  @media only screen and (min-width: 930px) {
    height: 100vh;
    width: 100%;
    max-width: 1200px;
  }
`;

const LayoutStyled = styled.div`
  width: 100vw;
  height: 100%;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  @media only screen and (min-width: 930px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default Layout;
