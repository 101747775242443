import React, { FC } from "react";
import styled from "styled-components";
import { URL } from "../api/config";

type WrapperType = {
  bg: string;
};

type TitleType = {
  bg: string;
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Title: FC<TitleType> = ({ bg, title, ...restProps }) => {
  return (
    <Wrapper {...restProps} bg={URL + bg}>
      <p>{title}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperType>`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  background-image: url(${(props) => props.bg});
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  p {
    text-align: center;
    color: ${(props) => props.theme.paletteColors.primary};
    font-family: ${(props) => props.theme.police.primary}, "Cairo";
    font-size: 38px;
  }
`;

export default Title;
