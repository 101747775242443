import {
  FilePayload,
  AccessMenuProductPayload,
  WidgetPayload,
} from "../../api/types";
import { getFont } from "../../utils/fonts";

export const scrollWindowToBottom = () => {
  const dctScrollPosition = document.documentElement.scrollTop;
  const endPostion =
    document.documentElement.scrollHeight - document.documentElement.scrollTop;
  if (endPostion !== dctScrollPosition) {
    window.scrollTo(0, endPostion);
  }
};

export const getWidgetStyles = (widget: WidgetPayload) => {
  const order = widget.order || 0;
  const fontSize =
    widget.styles?.[widget?.styles?.findIndex((w) => w.kind === "fontSize")]
      ?.value;
  const fontFamily = getFont(
    widget.styles?.[widget?.styles?.findIndex((w) => w.kind === "fontFamily")]
      ?.value || "Cairo"
  );
  const fontColor =
    widget.styles?.[widget?.styles?.findIndex((w) => w.kind === "fontColor")]
      ?.value || "#ffffff";

  const alignment =
    widget.styles?.[widget?.styles?.findIndex((w) => w.kind === "alignment")]
      ?.value === "CENTER"
      ? {
          textAlign: "center" as "center",
          maxWidth: 300,
          margin: "0 auto",
        }
      : {};
  return {
    padding: "0 15px",
    order,
    fontSize: fontSize ? `${fontSize}px` : "16px",
    fontFamily,
    color: fontColor,
    ...alignment,
  };
};

export const getProductPicture = (
  product: AccessMenuProductPayload,
  logo: string | null | undefined
) => {
  if (product.copiedPictures && product.copiedPictures.length > 0) {
    return product.copiedPictures.filter(
      (picture: FilePayload) => picture.quality === "HIGH"
    )?.[0]?.fileUrl;
  }
  if (product.picture?.fileUrl) {
    return product.picture?.fileUrl;
  }
  return logo;
};
