import styled from "styled-components";

import { URL } from "../../api/config";

export const Separator = styled.div`
  width: 100%;
  height: 20px;
`;

export const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${(props) =>
    props?.theme?.imageBackgroundHome
      ? `url(${props.theme.imageBackgroundHome}) no-repeat top center`
      : "none"};
  background-size: cover;
  overflow: hidden;
  .container {
    height: min-content;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: auto;
  }
  .container__logo {
    width: 200px;
    height: 200px;
    display: grid;
    overflow: hidden;
    .logo {
      width: 100%;
      height: 100%;
      margin: auto;
      object-fit: contain;
    }
  }
  .button__access {
    min-width: 100px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    padding: 0 15px;
    border: none;
    border-radius: ${({ theme }) => (theme.buttonBackground ? 0 : "20px")};
    color: #ffffff;
    background-color: ${({ theme }) =>
      theme.buttonBackground ? "none" : "rgba(127, 127, 127, 0.3)"};
    background-image: ${({ theme }) =>
      theme.buttonBackground ? `url(${URL}${theme.buttonBackground})` : "none"};
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    ${(props) =>
      props.theme.direction === "RTL" && "flex-direction: row-reverse;"}
    span {
      font-size: 16px;
      text-transform: capitalize;
      color: ${(props) => props.theme.paletteColors.primary};
    }
    svg {
      color: ${(props) => props.theme.paletteColors.primary};
      ${(props) =>
        props.theme.direction === "RTL"
          ? "margin-right: 10px"
          : "margin-left: 10px;"}
    }
  }
  .__info {
    width: 100%;
    margin-top: 60px;
    font-size: 12px;
    color: ${(props) => props.theme.paletteColors.primary};
    text-align: center;
    font-family: ${(props) => props.theme.police.secondary}, "Cairo",
      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .__description {
    margin: 0%;
    text-align: center;
    color: ${(props) => props.theme.paletteColors.primary};
    font-family: ${(props) => props.theme.police.secondary}, "Cairo",
      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .social__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 15px;
    .social__icon {
      margin: 0 4px;
      fill: ${({ theme }) => theme?.paletteColors.primary};
      cursor: pointer;
      path {
        fill: ${({ theme }) => theme?.paletteColors.primary};
      }
    }
  }
  a.footer__link {
    position: fixed;
    bottom: 20px;
    text-decoration: none;
    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      font-size: 12px;
    }
  }
  .footer__info {
    position: relative;
    z-index: 9;
  }
  .language-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999;
  }
  .shadow {
    height: 100%;
    position: absolute;
    bottom: -20px;
    z-index: 1;
    background-color: #0000007a;
    filter: blur(30px);
  }
  @media only screen and (min-width: 930px) {
    width: 100%;
    height: 100vh;
    .container {
      height: 80vh;
      p.info {
        margin-top: 200px;
      }
    }
    .language-btn {
      right: calc(50vw - 580px);
    }
  }
`;
