import React, { FC } from "react";
import styled from "styled-components";
import { X } from "react-feather";
import { AccessMenuCategoryProductPayload } from "../api/types";

type ModalType = {
  show: boolean;
  onClose: () => void;
  product?: AccessMenuCategoryProductPayload;
};

type ContainerType = {
  show: boolean;
};

const ModalPromotion: FC<ModalType> = ({ show, onClose, children }) => {
  const close = () => {
    onClose();
  };

  return (
    <Wrapper show={show}>
      <div
        onClick={close}
        onKeyDown={close}
        role="button"
        tabIndex={0}
        aria-label="button"
        className="modal__overlay"
      />
      <div className="container">
        <div className="modal__content">
          <button onClick={close} type="button">
            <X />
          </button>
          {children}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<ContainerType>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: ${(props) => (props.show === true ? "99999" : "-1")};
  display: grid;
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  opacity: ${(props) => (props.show === true ? "1" : "0")};
  overflow-y: scroll;
  .modal__overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    background: ${(props) =>
      props.show === true ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)"};
    transition: all 0.3s cubic-bezier(0.18, 1.04, 0.7, 1);
  }
  .container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal__content {
    width: 90%;
    max-width: 500px;
    position: relative;
    bottom: ${(props) => (props.show === true ? "unset" : "-100vh")};
    margin: auto;
    background: white;
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.18, 1.04, 0.7, 1);
  }
  img {
    width: 100%;
    margin-bottom: -9px;
    padding: 0;
    border-radius: 10px;
  }
  button {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0 6px;
    border-radius: 50%;
    background: rgba(127, 127, 127, 0.3);
    border: 0;
    outline: none;
    svg {
      width: 24px;
      height: 24px;
      color: #ffffff;
    }
  }
  .content-header {
    display: grid;
    grid-template-columns: auto 80px;
  }
  .more {
    width: calc(100% - 40px);
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    background-color: black;
    color: white;
    text-decoration: none;
    border-radius: 6px;
  }
  @media only screen and (min-width: 930px) {
    width: 100%;
    height: 100%;
    max-height: 1024px;
    .modal__overlay {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export default ModalPromotion;
