/* eslint-disable react/no-array-index-key */
//  libraries imports
import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";

//  local imports
import { URL } from "../api/config";
import Item from "./Item";
import { getFont } from "../utils/fonts";
import {
  AccessMenuSubCategoryDetailPayload,
  AccessMenuProductPayload,
  FilePayload,
  GetCategoryWidgetsQuery,
  GetCategoryWidgetsQueryVariables,
  WidgetPayload,
} from "../api/types";
import { GET_WIDGETS } from "../api/query.api";

//  type
type SubcategoryType = {
  categoryId: string;
  decoration: string;
  subcategory: AccessMenuSubCategoryDetailPayload;
  handleShowProduct: (product: AccessMenuProductPayload) => void;
  language: number;
  logo: string;
} & React.HTMLAttributes<HTMLDivElement>;
type Display = "GRID" | "LIST" | "CENTER";

const Subcategory = React.forwardRef<HTMLDivElement, SubcategoryType>(
  (
    {
      categoryId,
      subcategory,
      decoration,
      language,
      handleShowProduct,
      logo,
      ...restProps
    },
    ref
  ) => {
    //  query
    const { data: widgets, loading: loadingWidgets } = useQuery<
      GetCategoryWidgetsQuery,
      GetCategoryWidgetsQueryVariables
    >(GET_WIDGETS, {
      variables: {
        input: {
          area: categoryId,
        },
      },
      fetchPolicy: "network-only",
    });

    //  local variables
    const widgetsList = widgets?.getAreaWidgets.widgets;

    //  functions
    const getProductPicture = (product: AccessMenuProductPayload) => {
      if (product.copiedPictures && product.copiedPictures.length > 0) {
        return product.copiedPictures.filter(
          (picture: FilePayload) => picture.quality === "HIGH"
        )?.[0]?.fileUrl;
      }
      if (product.picture?.fileUrl) {
        return product.picture?.fileUrl;
      }
      return logo;
    };

    const getWidgetStyles = (widget: WidgetPayload) => {
      const order = widget.order || 1;
      const fontSize =
        widget.styles?.[widget?.styles?.findIndex((w) => w.kind === "fontSize")]
          ?.value || "16px";
      const fontFamily = getFont(
        widget.styles?.[
          widget?.styles?.findIndex((w) => w.kind === "fontFamily")
        ]?.value || "Cairo"
      );
      const fontColor =
        widget.styles?.[
          widget?.styles?.findIndex((w) => w.kind === "fontColor")
        ]?.value || "#ffffff";
      return {
        padding: "0 15px",
        order,
        fontSize,
        fontFamily,
        color: fontColor,
      };
    };

    return (
      <Wrapper
        order={subcategory?.order || 0}
        {...restProps}
        subTitlePicture={decoration}
        URL={URL}
        ref={ref}
      >
        {!loadingWidgets && (
          <div
            className={
              subcategory.display === "GRID"
                ? "products-grid-container"
                : "products-list-container"
            }
            style={{ order: subcategory.order || 0 }}
          >
            <span
              className="subcategory-title"
              id={subcategory?.identifier || ""}
            >
              {subcategory?.names &&
                (subcategory?.names[language]?.value || "")}
            </span>
            {subcategory.products &&
              subcategory?.products?.map(
                (product: AccessMenuProductPayload, index: number) => {
                  return (
                    product.names?.[language]?.value && (
                      <Item
                        key={index}
                        display={subcategory.display as Display}
                        show={() => {
                          handleShowProduct(product);
                        }}
                        data={product}
                        image={getProductPicture(product) || ""}
                        order={product.order || 1}
                      />
                    )
                  );
                }
              )}

            {widgetsList?.map((widget, index) => {
              return (
                <p key={index} style={getWidgetStyles(widget)}>
                  {widget.content?.textContent?.[language]?.value}
                </p>
              );
            })}
          </div>
        )}
      </Wrapper>
    );
  }
);

const Wrapper = styled.div<{
  order: number;
  subTitlePicture: string;
  URL: string | undefined;
}>`
  order: ${(props) => props?.order};
  .products-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px 10px;
    p {
      grid-column: span 2;
    }
  }
  /* .products-list-container {
    max-width: 768px;
  } */
  .subcategory-title {
    width: 100%;
    display: block;
    font-size: 24px;
    background: ${(props) =>
      props.subTitlePicture === "#"
        ? ""
        : `url(${props.URL + props.subTitlePicture}) no-repeat center`};
    background-size: min(85%, 350px) 100%;
    color: ${(props) =>
      props.subTitlePicture === "#"
        ? props.theme.paletteColors.secondary
        : "#444444 !important"};
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
    text-align: center;
    grid-column: span 2;
    ::first-letter {
      text-transform: ${(props) =>
        props.theme.direction === "RTL" ? "unset" : "uppercase"};
    }
  }
  .color {
    color: ${(props) => props.theme.paletteColors.primary};
  }
  @media only screen and (min-width: 930px) {
    .list-container {
      height: calc(100vh - 250px); /*290*/
      padding: 0 0 20px 0;
      overflow-y: scroll;
    }
    .products-grid-container {
      max-width: 930px;
      height: auto;
      grid-template-columns: 31.11% 31.11% 31.11%;
      grid-template-rows: min-content;
      column-gap: 3.33%;
      margin: auto;
      overflow-y: scroll;
      .subcategory-title,
      p {
        grid-column: span 3;
      }
    }
  }
`;

export default Subcategory;
