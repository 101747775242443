import React, { FC } from "react";
import styled from "styled-components";

type RadioProps = { id: string } & React.AllHTMLAttributes<HTMLInputElement>;

const Radio: FC<RadioProps> = ({ id, label, ...restProps }) => {
  return (
    <Wrapper>
      <input id={`radio-${id}`} type="radio" {...restProps} />
      <label htmlFor={`radio-${id}`}>{label}</label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 2px;
  input[type="radio"] {
    opacity: 0;
    width: 20px;
    + label {
      position: relative;
      display: inline-block;
      line-height: 28px;
      color: #000000;
      font-weight: 400;
      font-family: ${(props) =>
        props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        display: inline-block;
        ${({ theme }) =>
          theme.direction === "RTL" ? "right: -29px" : "left: -29px"};
        top: 4px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        border: 2px solid #000000;
      }
      &::after {
        content: "";
        position: absolute;
        display: none;
        ${({ theme }) =>
          theme.direction === "RTL" ? "right: -25px" : "left: -25px"};
        top: 8px;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background: #000000;
      }
    }
    &:checked {
      + label::after {
        display: inline-block;
      }
    }
    &:focus {
      + label::before {
        box-shadow: 0 0px 5px #0453bb;
      }
    }
    &:disabled {
      + label {
        color: #d8d8d8;
        &::after {
          backaground: #d8d8d8;
        }

        &::before {
          border-color: #d8d8d8;
        }
      }
      label::before {
        color: #d8d8d8;
      }
    }
  }
`;

export default Radio;
