import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { Appbar } from "../../Commons";
import { URL } from "../../api/config";

type ParamsType = {
  url?: string | null;
  menuId: string | null;
};
const StayWithUs: React.FC = () => {
  const { state } = useLocation<ParamsType>();
  const { url, menuId } = state;
  return (
    <MasterContainer>
      <Container>
        <Wrapper>
          <Appbar />
          <Separator />
          <div style={{ margin: "auto", width: "60%" }}>
            <ReactPlayer
              controls
              playing
              loop
              muted
              width="100%"
              url={`${URL}/menu/images/${menuId}/menu_ads_video/${url}`}
            />
          </div>
        </Wrapper>
      </Container>
    </MasterContainer>
  );
};

const Container = styled.div`
  @media only screen and (min-width: 930px) {
    height: 100vh;
    width: 100%;
    overflow: scroll;
    position: relative;
  }
`;

const Separator = styled.div`
  height: 20px;
  width: 100%;
  @media only screen and (min-width: 930px) {
    height: 35px;
  }
`;

const Wrapper = styled.div`
  padding: 0 0px 60px 0px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "appbar" "categorieList";
  direction: ${(props) => (props?.theme?.direction === "RTL" ? "rtl" : "ltr")};

  .products-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px 10px;
    direction: ${(props) => (props.theme.direction === "RTL" ? "rtl" : "ltr")};
  }

  @media only screen and (min-width: 930px) {
    grid-area: categorieList;
    .products-grid-container {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      max-width: 1100px;
    }
    .menu {
      display: grid;
      grid-gap: 20px;
      margin: 0 auto;
      max-width: 920px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

const MasterContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  max-width: 1200px;
  .language-btn {
    display: none;
  }
  .content {
    display: grid;
    grid-template-columns: 70px auto 70px;
    position: relative;
    z-index: 8;
    max-width: 460px;
    margin: 0px auto 0 !important;
  }
  #header {
    position: initial;
    padding: 0;
    display: none;
  }
  .mobile-header {
    display: block;
  }
  .desktop-main-header {
    display: none;
  }
  @media only screen and (min-width: 930px) {
    .content {
      margin: 20px auto 0px;
    }
    #header {
      height: 125px;
      display: grid;
      grid-template-columns: 30% 68%;
      grid-column-gap: 2%;
      position: sticky;
      top: 0px;
      padding: 0;
      .desktop-header {
        ${(props) =>
          props.theme.direction === "RTL"
            ? "margin: 20px 0 0 auto"
            : "margin: 20px 0 0 0"};
        width: 220px;
        grid-column: span 2;
      }
    }
    .language-btn {
      display: block;
      position: absolute;
      ${(props) =>
        props.theme.direction === "RTL"
          ? "left: calc(50vw - 475px)"
          : "right: calc(50vw - 475px)"};
      top: 55px;
    }

    .mobile-header {
      display: none;
    }
    .desktop-main-header {
      display: block;
    }
  }
`;

export default StayWithUs;
