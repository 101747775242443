//  libraries import
import React, { FC } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//  local imports
import bag from "../Images/Bag.svg";
import { getCookie } from "../utils/util";
// eslint-disable-next-line import/no-cycle
import { ModalCart } from ".";

import {
  GetMenuCartDetailsQuery,
  GetMenuCartDetailsQueryVariables,
  GetStripePublicKeyQuery,
  GetStripePublicKeyQueryVariables,
} from "../api/types";
import { GET_MENU_CART_DETAILS, GET_STRIPE_PUBLIC_KEY } from "../api/query.api";

type CartType = {
  id: string;
  className?: string;
};

type WrapperType = {
  show: boolean;
  className?: string;
};

const Cart: FC<CartType> = ({ id, className }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  // QUERIES
  const { data: cart } = useQuery<
    GetMenuCartDetailsQuery,
    GetMenuCartDetailsQueryVariables
  >(GET_MENU_CART_DETAILS, {
    skip: !id || !getCookie(),
    variables: { input: { device: getCookie()!, menu: id } },
    fetchPolicy: "network-only",
  });

  const { data: StripePublicKey } = useQuery<
    GetStripePublicKeyQuery,
    GetStripePublicKeyQueryVariables
  >(GET_STRIPE_PUBLIC_KEY, {
    variables: {
      input: {
        menu: id,
      },
    },
  });

  const stripePromise = React.useMemo(() => {
    if (StripePublicKey?.getStripePublicKey?.publicKey) {
      return loadStripe(StripePublicKey.getStripePublicKey.publicKey);
    }
    return null;
  }, [StripePublicKey]);
  // FUNCTIONS
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Wrapper show={open} className={className}>
      <Elements stripe={stripePromise}>
        <ModalCart onClose={handleClose} show={open} data={cart} menu={id} />
      </Elements>
      <button
        type="button"
        className="fab"
        onClick={() => setOpen(true)}
        id="cartFAB"
      >
        <div className="badge">{cart?.getMenuCartDetails.count}</div>
        <img src={bag} alt="bag" />
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperType>`
  height: 60px;
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  .fab {
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 20px;
    ${(props) =>
      props.theme.direction === "RTL" ? "left: 20px;" : "right: 20px;"}
    z-index: 9;
    padding-top: 5px;
    background-color: white;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    border: 0;
    pointer-events: all;
    outline: none;
    transition: all 0.4s cubic-bezier(0.34, 0.38, 0.18, 1.04);
    .badge {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -5px;
      right: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Cairo, Cambria, Cochin, Georgia, Times, "Times New Roman",
        serif;
      background: black;
      color: white;
      border-radius: 50%;
    }
  }
  .hideCart {
    right: -70px;
  }
  @media only screen and (min-width: 930px) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    grid-area: -1 / -2;
    grid-area: cart;
    ${(props) => props.show && "z-index: 99;"}
    .fab {
      position: unset;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      margin: 0;
    }
  }
`;

export default Cart;
