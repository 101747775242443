import React, { FC, Fragment } from "react";
import styled from "styled-components";
import findIndex from "lodash/findIndex";
import { useQuery } from "@apollo/client";

import { Radio, Checkbox } from "../../../Commons";
import { getPrice } from "../../../utils/util";
import { URL } from "../../../api/config";
import { getProductPicture, getProductUnit } from "../ModalProduct--utils";

import {
  ProductDetailsPayload,
  MenuCartSupplementInput,
} from "../../../api/types";
import {
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../../../Graphql/apolloClient";

type Options = {
  [key: string]: MenuCartSupplementInput[];
};

type TabExtrasProps = {
  options: Options;
  product: ProductDetailsPayload;
  errors: { [key: string]: string };
  onChangeOptions: (options: Options) => void;
};

const TabExtras: FC<TabExtrasProps> = ({
  product,
  options,
  errors,
  onChangeOptions,
}) => {
  const { data: localState } = useQuery<GetLocalStateQuery>(GET_LOCAL_STATE);
  const localLanguage = localState?.language || "EN";
  const localPrecision = localState?.precision || "1";

  const language: number = findIndex(product?.names, {
    languageCode: localLanguage,
  });

  const isChecked = (extraId: string, optionId: string) => {
    if (options[extraId]) {
      return options[extraId].findIndex((item) => item.id === optionId) !== -1;
    }
    return false;
  };

  const handleChangeOption = (
    extraId: string,
    optionId: string,
    kind?: "radio" | "check"
  ) => {
    if (!options[extraId] || kind === "radio") {
      onChangeOptions({ ...options, [extraId]: [{ id: optionId }] });
    } else {
      const optionIndex = options[extraId].findIndex(
        (option) => optionId === option.id
      );
      if (optionIndex !== -1) {
        const newOptions = { ...options };
        newOptions[extraId].splice(optionIndex, 1);

        if (newOptions[extraId].length > 0) {
          onChangeOptions(newOptions);
        } else {
          delete newOptions[extraId];
          onChangeOptions(newOptions);
        }
      } else {
        onChangeOptions({
          ...options,
          [extraId]: [...options[extraId], { id: optionId }],
        });
      }
    }
  };

  return (
    <Wrapper>
      <div className="tab__header">
        {getProductPicture(product) && (
          <div className="image-container">
            <img src={URL! + getProductPicture(product)} alt="modal" />
          </div>
        )}
        <div className="container__title-price">
          <h3 className="order-0">
            {product &&
              product.names &&
              product.names.length > 0 &&
              (product.names?.[language]?.value || "")}
          </h3>
          <div className="product-price__container">
            {product?.discount?.price?.value && (
              <span className="product-price product-price--main">
                {`${getPrice(
                  product?.discount?.price?.value.toString(),
                  localPrecision
                )} `}
                {product?.discount?.price?.currency?.code || ""}
              </span>
            )}

            {product?.price?.value ? (
              <span
                className={`product-price ${
                  product?.discount?.price?.value
                    ? "product-price--unselected"
                    : "product-price--main"
                }`}
              >
                {`${getPrice(
                  product?.price?.value.toString(),
                  localPrecision
                )} `}
                {product?.price?.currency?.code || ""}
              </span>
            ) : null}
            <span className="product-price">
              &nbsp;
              {getProductUnit(product?.unit?.kind || "")}
            </span>
          </div>
        </div>
      </div>
      <div className="tab__content">
        {product?.choices?.map((extra) => (
          <Fragment key={extra.id}>
            <span className="extra__name">
              {extra.names?.[language]?.value}
            </span>
            {extra?.supplements?.map((option) => (
              <div className="option__row" key={option?.id}>
                {extra.maxItems === 1 ? (
                  <Radio
                    key={option.id}
                    id={option.id!}
                    label={option.names?.[language].value || ""}
                    name={extra?.names?.[language]?.value || ""}
                    checked={isChecked(extra.id!, option.id!)}
                    onChange={() =>
                      handleChangeOption(extra.id!, option.id!, "radio")
                    }
                  />
                ) : (
                  <Checkbox
                    key={option.id}
                    id={option.id!}
                    label={option.names?.[language].value || ""}
                    name={extra?.names?.[language]?.value || ""}
                    checked={isChecked(extra.id!, option.id!)}
                    onChange={() =>
                      handleChangeOption(extra.id!, option.id!, "check")
                    }
                  />
                )}
                <div className="option__prices">
                  {option?.price?.value && (
                    <span
                      className={`product-price ${
                        option?.discount?.price?.value
                          ? "product-price--unselected"
                          : ""
                      }`}
                    >
                      {`${getPrice(
                        option?.price?.value.toString(),
                        localPrecision
                      )} `}
                      {option?.price?.currency?.code || ""}
                    </span>
                  )}
                  {option?.discount?.price?.value && (
                    <span className="product-price">
                      {`${getPrice(
                        option?.discount?.price?.value.toString(),
                        localPrecision
                      )} `}
                      {option?.discount?.price?.currency?.code || ""}
                    </span>
                  )}
                </div>
              </div>
            ))}
            {errors[extra.id!] && (
              <span className="extra__error">{errors[extra.id!]}</span>
            )}
          </Fragment>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: scroll;

  .product-price {
    margin: 0 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
    color: #42424a;
  }
  .product-price--unselected {
    color: #9595a3;
    text-decoration: line-through;
  }
  .tab__header {
    display: flex;
    padding: 0 20px 0 10px;
    .image-container {
      width: 110px;
      height: 70px;
      object-fit: cover;
      overflow: hidden;
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .container__title-price {
      display: flex;
      flex-direction: column;
      .order-0 {
        order: 0;
      }
    }
    .product-price__container {
      display: flex;
      padding: 0 15px;

      .product-price--main {
        color: #fb2020;
      }
    }
  }
  .tab__content {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    direction: ${(props) => (props.theme.direction === "RTL" ? "rtl" : "ltr")};
    > span {
      margin-top: 10px;
      font-weight: bold;
    }
    .extra__name {
      color: #42424a;
      font-weight: bold;
      font-family: ${(props) =>
        props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
    }
    .option__row {
      /* display: flex;
      justify-content: space-between;
      align-items: center; */
      display: grid;
      grid-template-columns: 1fr 75px;
      .option__prices {
        display: flex;
        justify-content: flex-end;
        padding-top: 7px;
      }
    }
    .extra__error {
      font-family: Cairo, Cambria, Cochin, Georgia, Times, "Times New Roman",
        serif;
      font-weight: 400;
      color: #fb2020;
    }
  }
`;

export default TabExtras;
