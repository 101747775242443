//  libraries
import React, { FC } from "react";
import { MapPin, Phone } from "react-feather";

//  local imports
import { Wrapper } from "./styles";
import { Facebook } from "../../Images/social";

const Contact: FC = () => {
  return (
    <Wrapper>
      <div className="contact-area">
        <h1>Get in toutch with us!</h1>
        <div className="contact-form">
          <input name="Nom" type="text" placeholder="Nom" />
          <input name="Prénom" type="text" placeholder="Prénom" />
          <input
            name="email"
            type="email"
            placeholder="Email"
            className="input__email"
          />
          <textarea rows={5} placeholder="Message" />
          <button type="button" className="button__send">
            Envoyer
          </button>
        </div>
      </div>
      <div className="footer-area">
        <h3>About Us</h3>
        <h3 className="__contact-us">Contact Us</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi,
          nostrum in doloribus id delectus tempore error veritati.
        </p>
        <div>
          <div className="contact__info">
            <MapPin size="20" />
            <p>map</p>
          </div>
        </div>
        <div>
          <div className="contact__info">
            <Phone size="20" />
            <p>phone</p>
          </div>
        </div>
        <ul>
          <a href="/#">
            <li>
              <Facebook width=" 32" height=" 32" />
            </li>
          </a>
          <a href="/#">
            <li>
              <Facebook width=" 32" height=" 32" />
            </li>
          </a>
        </ul>
      </div>
      <div className="copyrights">
        <p>Copyright reserved 2020</p>
      </div>
    </Wrapper>
  );
};

export default Contact;
