//  libraries imports
import React, { FC } from "react";
import styled from "styled-components";
import { Plus, Minus } from "react-feather";

//  types
type QuatityType = {
  quantity: string;
  unit: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onAdd: () => void;
  onReduce: () => void;
};

type WrapperType = {
  unit: boolean;
};

const QuantitySelector: FC<QuatityType> = ({
  quantity,
  unit,
  onChange,
  onAdd,
  onReduce,
  disabled,
}) => {
  //  functions
  const handleQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (unit !== "PIECE") {
      onChange(event.target.value);
    } else if (
      event.target.value.slice(-1) !== "," &&
      event.target.value.slice(-1) !== "."
    ) {
      onChange(event.target.value);
    }
  };

  const getProductUnit = (kind: string) => {
    switch (kind) {
      case "KILOGRAM":
        return "kg";
      case "METER":
        return "m ";
      case "GRAM":
        return "g ";
      default:
        return "";
    }
  };

  return (
    <Wrapper unit={unit !== "PIECE"}>
      <button
        disabled={disabled}
        type="button"
        className="button__minus"
        onClick={onReduce}
      >
        <Minus color="#9A9EA9" />
      </button>
      <input
        value={quantity}
        onChange={handleQuantity}
        type="number"
        disabled={disabled}
      />
      {unit !== "PIECE" && (
        <Adorment>
          <span>{getProductUnit(unit)}</span>
        </Adorment>
      )}
      <button
        disabled={disabled}
        type="button"
        className="button__plus"
        onClick={onAdd}
      >
        <Plus color="#9A9EA9" />
      </button>
    </Wrapper>
  );
};

const Adorment = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 10px;
  background-color: #f0f0f4;
  border-radius: 5px 0 0 5px;
  color: #9a9ea9;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
`;

const Wrapper = styled.div<WrapperType>`
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background: #fcfafa;
  border-radius: 25px;
  font-family: Cairo, Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  direction: ltr;
  input {
    width: 42px;
    height: 40px;
    padding: 0 6px;
    text-align: center;
    font-size: 19px;
    background-color: transparent;
    color: #696969;
    border: 0;
  }
  button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f4;
    border: 0;
  }
  .button__plus {
    border-radius: ${(props) =>
      props.unit ? "0px 20px 20px 0px" : "5px 20px 20px 5px"};
  }
  .button__minus {
    border-radius: 20px 5px 5px 20px;
  }
`;

export default QuantitySelector;
