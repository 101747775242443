import styled from "styled-components";

export const Snack = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 8vh;
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
  > div.snack-content {
    width: fit-content;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 15px;
    font-family: "Cairo";
    background-color: #26292b;
    color: white;
    border-radius: 30px;
    font-size: 16px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    span {
      margin-left: 10px;
    }
  }
  @media only screen and (min-width: 930px) {
    left: 0;
  }
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
  #container {
    width: 100px;
    height: 100px;
    margin: auto auto;
    transform: scale(0.6);
  }
  span {
    display: block;
    margin: 5px;
    border-left: 5px solid;
    border-top-left-radius: 100%;
    border-top: 5px solid;
    animation: Loader 1500ms linear infinite forwards;
  }
  .sp1 {
    width: 40px;
    height: 40px;
    border-left-color: ${(props) => props.color};
    border-top-color: ${(props) => props.color};
  }
  .sp2 {
    width: 30px;
    height: 30px;
    border-left-color: ${(props) => props.color};
    border-top-color: ${(props) => props.color};
    opacity: 0.6;
  }
  .sp3 {
    width: 20px;
    height: 20px;
    border-left-color: ${(props) => props.color};
    border-top-color: ${(props) => props.color};
    opacity: 0.3;
  }

  @keyframes Loader {
    0% {
      transform: rotate(0deg);
      transform-origin: right bottom;
    }
    25% {
      transform: rotate(90deg);
      transform-origin: right bottom;
    }
    50% {
      transform: rotate(180deg);
      transform-origin: right bottom;
    }
    75% {
      transform: rotate(270deg);
      transform-origin: right bottom;
    }
    100% {
      transform: rotate(360deg);
      transform-origin: right bottom;
    }
  }
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0;
  border: 0;
  border-bottom: 1px solid rgba(219, 216, 213, 0.3);
`;

type ContainerType = {
  show: boolean;
  shop: boolean;
};

export const Wrapper = styled.div<ContainerType>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: ${(props) => (props.show === true ? "99999" : "-1")};
  display: flex;
  justify-content: center;
  align-items: baseline;
  opacity: ${(props) => (props.show === true ? "1" : "0")};
  overflow-y: scroll;
  .modal__overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    background: ${(props) =>
      props.show === true ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)"};
    transition: all 0.3s cubic-bezier(0.18, 1.04, 0.7, 1);
  }
  .modal__content {
    width: 95%;
    max-width: 500px;
    height: unset;
    max-height: calc(100vh - 120px);
    position: fixed;
    bottom: ${(props) => (props.show === true ? "2.5vw" : "-100vh")};
    display: grid;
    grid-template-rows: ${(props) =>
      props.shop === true ? "1fr 174px" : "auto"};
    background: #ffffff;
    border-radius: 20px;
    transition: all 0.3s cubic-bezier(0.18, 1.04, 0.7, 1);
    overflow: hidden;
  }

  button.close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: auto;
    background: ${({ theme }) =>
      theme.circularButtonBackground
        ? `url(${URL}${theme.circularButtonBackground})`
        : " rgba(127, 127, 127, 0.3)"};
    background-size: contain;
    border: 0;
    border-radius: 50%;
    outline: none;
    svg {
      stroke: #000000;
    }
  }
  h3 {
    /* order: ${(props) => (props.theme.direction === "RTL" ? "2" : "1")}; */
    color: #444444;
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
    margin: 15px 15px 10px 15px;
    letter-spacing: 0px;
    line-height: 18px;
    /* text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"}; */
    ::first-letter {
      ${(props) => (props.theme.direction === "RTL" ? "unset" : "uppercase")};
    }
  }

  p {
    margin: 10px 0 15px 0;
    padding: 0 15px;
    font-size: 14px;
    line-height: 15px;
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
    font-family: ${(props) =>
      props.theme.direction === "RTL" ? "Cairo" : props.theme.police.secondary};
    ::first-letter {
      ${(props) => (props.theme.direction === "RTL" ? "unset" : "uppercase")};
    }
  }

  .quatity {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 10px 8px 25px;
    font-family: Cairo, Cambria, Cochin, Georgia, Times, "Times New Roman",
      serif;
  }

  .cart-manager {
    width: 100%;
    height: fit-content;
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .note {
    height: 40px;
    width: calc(100% - 60px);
    grid-column: span 3;
    padding: 0 15px;
    margin: 5px 15px 10px 15px;
    background-color: ghostwhite;
    border: 0;
    border-radius: 30px;
    font-family: Cairo;
    font-size: 15px;
    outline: none;
  }
  .button__add-cart {
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: ${(props) =>
      props.theme.direction === "RTL" ? "row-reverse" : "unset"};
    justify-content: center;
    align-items: center;
    border: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgba(219, 216, 213, 0.3);
    color: #000000;
    font-family: Cairo;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    .img__icon {
      width: 20px;
      margin: 0 15px 0 0;
    }
  }
  .button__add-cart--disable {
    opacity: 0.3;
    cursor: auto;
  }
  .animate {
    animation: show 3s ease-in-out 0.2s 1 alternate forwards;
  }
  @keyframes show {
    0% {
      opacity: 0;
      bottom: 8vh;
    }
    7% {
      opacity: 1;
      bottom: 10vh;
    }
    93% {
      opacity: 1;
      bottom: 10vh;
    }
    100% {
      opacity: 0;
      bottom: 8vh;
    }
  }
  @media only screen and (min-width: 930px) {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    .modal__overlay {
      width: 100%;
      height: 100%;
      top: unset;
    }
    .modal__content {
      width: 55vh;
      max-height: 85vh;
      height: unset;
      bottom: 7.5vh;
      overflow-y: scroll;
    }
  }
  @media only screen and (min-height: 1024px) {
    .modal__content {
      bottom: calc(50vh - 38vh);
    }
  }
`;
