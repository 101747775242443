/* eslint-disable radix */
/* eslint-disable prefer-template */
/* eslint-disable no-else-return */
import { getLuminance, darken, lighten } from "polished";

import { translate } from "./dictionary";
import digipagesFavIcon from "../Images/favdigipages.png";
import digimenuFavIcon from "../Images/favdigimenu.png";
import menumeFavIcon from "../Images/favmenume.png";

export const lock = () => {
  const body = document.getElementsByTagName("body")[0];
  body.setAttribute("class", "lock");
};
export const unlock = () => {
  const body = document.getElementsByTagName("body")[0];
  body.removeAttribute("class");
};

export const getProductUnit = (kind: string, language: string) => {
  switch (kind) {
    case "KILOGRAM":
      return translate(language, 36);
    case "METER":
      return translate(language, 37);
    case "PIECE":
      return translate(language, 38);
    case "GRAM":
      return translate(language, 40);
    default:
      return translate(language, 36);
  }
};

export const getCookie = () => {
  let device = "";
  const cookies = document.cookie.split(";");
  if (cookies?.[0]?.split("=")[0] === "token") {
    device = cookies[0]?.split("=")?.[1];
  } else {
    device = cookies?.[1]?.split("=")?.[1];
  }
  return device;
};

export const getPrice = (price: string, precision: string) => {
  const complement = "000";
  switch (precision) {
    case "0":
      return price.split(".")[0];
    case "1":
      return (
        price.split(".")?.[0] +
        "." +
        (price.split(".")?.[1]?.substr(0, 1) || "0")
      );
    case "2":
      if (price.split(".")?.[1]?.length >= 2) {
        return (
          price.split(".")?.[0] + "." + price.split(".")?.[1]?.substr(0, 2)
        );
      } else {
        return (
          price.split(".")?.[0] +
          "." +
          ((price.split(".")?.[1] || "") +
            complement.substr(
              0,
              parseInt(precision) - (price.split(".")?.[1]?.length || 0)
            ))
        );
      }
    case "3":
      if (price.split(".")?.[1]?.length >= 3) {
        return (
          price.split(".")?.[0] + "." + price.split(".")?.[1]?.substr(0, 3)
        );
      } else {
        return (
          price.split(".")?.[0] +
          "." +
          ((price.split(".")?.[1] || "") +
            complement.substr(
              0,
              parseInt(precision) - (price.split(".")?.[1]?.length || 0)
            ))
        );
      }
    default:
      return price.split(".")[0];
  }
};

export const getDomain = () => {
  const path = window.location.origin;
  if (path?.includes("menume")) return "menume";
  if (path?.includes("digimenu")) return "digimenu";
  if (path?.includes("digipages")) return "digipages";
  return undefined;
};

export const getFavIcon = () => {
  const domain = getDomain();
  switch (domain) {
    case "digimenu":
      return digimenuFavIcon;
    case "menume":
      return menumeFavIcon;
    default:
      return digipagesFavIcon;
  }
};

export const getData = () => {
  if (process.env.NODE_ENV === "development") {
    // return "linkmenu";
    // return "astrolab";
    // return "ink-cafe";
    // return "market";
    return "port-w-cafe";
  }
  if (window.location.origin.includes("qr")) {
    return window.location.host.split(".")[1];
  } else {
    return window.location.host.split(".")[0];
  }
};

export const getPathName = () => {
  return window.location.pathname;
};

// darker or lighter a color

export const getLighterOrDarker = (color: string) => {
  const luminance = getLuminance(color);
  if (luminance > 0.5) {
    return darken(0.3, color);
  } else {
    return lighten(0.3, color);
  }
};
