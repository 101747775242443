import styled from "styled-components";

type SearchType = {
  show: boolean;
};

export const Container = styled.div`
  @media only screen and (min-width: 930px) {
    height: 100vh;
    width: 100%;
    overflow: scroll;
    position: relative;
  }
`;

export const Separator = styled.div`
  height: 20px;
  width: 100%;
  @media only screen and (min-width: 930px) {
    height: 35px;
  }
`;

export const Wrapper = styled.div`
  padding: 0 0px 60px 0px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "appbar" "categorieList";
  direction: ${(props) => (props?.theme?.direction === "RTL" ? "rtl" : "ltr")};

  .products-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px 10px;
    direction: ${(props) => (props.theme.direction === "RTL" ? "rtl" : "ltr")};
  }
  .content {
    display: grid;
    width: calc(100% - 40px);
    grid-template-columns: 70px auto 70px;
    position: relative;
    z-index: 9;
    max-width: 460px;
    margin: 20px auto 0px;
    padding: 0 20px;
  }
  /* button {
    width: 40px;
    height: 40px;
    background: rgba(127, 127, 127, 0.3);
    border: 0;
    outline: none;
    border-radius: 50%;
    margin: auto 10px auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .button__access {
    margin: 20px auto;
    min-width: 100px;
    height: 40px;
    width: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    padding: 0 15px;
    border: none;
    border-radius: ${({ theme }) => (theme.buttonBackground ? 0 : "20px")};
    color: #ffffff;
    background-color: ${({ theme }) =>
      theme.buttonBackground ? "none" : "rgba(127, 127, 127, 0.3)"};
    background-image: ${({ theme }) =>
      theme.buttonBackground ? `url(${URL}${theme.buttonBackground})` : "none"};
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    ${(props) =>
      props.theme.direction === "RTL" && "flex-direction: row-reverse;"}
    span {
      font-size: 16px;
      text-transform: capitalize;
      color: ${(props) => props.theme.paletteColors.primary};
    }
  }
  .menu {
    display: grid;
    grid-gap: 20px;
    margin: 0 auto;
    max-width: 920px;
    grid-template-columns: 1fr;
  }
  .color {
    color: ${(props) => props.theme.paletteColors.primary};
  }

  @media only screen and (min-width: 930px) {
    grid-area: categorieList;
    .products-grid-container {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      max-width: 1100px;
    }
    .menu {
      display: grid;
      grid-gap: 20px;
      margin: 0 auto;
      max-width: 920px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export const SearchArea = styled.div<SearchType>`
  margin: auto;
  width: calc(100% - 40px);
  padding: 0 20px;
  max-width: 690px;
  position: relative;
  height: ${(props) => (props.show ? "50px" : "0")};
  transition: 0.2s all ease-in-out;
  overflow: hidden;

  svg {
    width: 18px;
    position: absolute;
    ${(props) =>
      props.theme.direction === "RTL" ? "right: 29px" : "left: 29px"};
    opacity: 0.3;
    top: 5px;
    z-index: 9;
  }
  .search-input {
    font-family: "Cairo";
    color: #3a3a46;
    font-size: 15px;
    height: 35px;
    width: calc(100% - 35px);
    padding: 0;
    border: 0;
    outline: none;
    border-radius: 8px;
    padding: ${(props) =>
      props.theme.direction === "RTL" ? "0 35px 0 0" : "0 0 0 35px"};
    background-color: #ffffff;
    transition: all 0.2s ease-in-out;
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
  }
  @media only screen and (min-width: 930px) {
    display: none;
  }
`;

export const SearchBtn = styled.button`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 0;
  outline: none;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MasterContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  max-width: 1200px;
  .language-btn {
    display: none;
  }
  .content {
    display: grid;
    grid-template-columns: 70px auto 70px;
    position: relative;
    z-index: 8;
    max-width: 460px;
    margin: 0px auto 0 !important;
  }
  #header {
    position: initial;
    padding: 0;
    display: none;
  }
  .mobile-header {
    display: block;
  }
  .desktop-main-header {
    display: none;
  }
  @media only screen and (min-width: 930px) {
    .content {
      margin: 20px auto 0px;
    }
    #header {
      height: 125px;
      display: grid;
      grid-template-columns: 30% 68%;
      grid-column-gap: 2%;
      position: sticky;
      top: 0px;
      padding: 0;
      .desktop-header {
        ${(props) =>
          props.theme.direction === "RTL"
            ? "margin: 20px 0 0 auto"
            : "margin: 20px 0 0 0"};
        width: 220px;
        grid-column: span 2;
      }
    }
    .language-btn {
      display: block;
      position: absolute;
      ${(props) =>
        props.theme.direction === "RTL"
          ? "left: calc(50vw - 475px)"
          : "right: calc(50vw - 475px)"};
      top: 55px;
    }

    .mobile-header {
      display: none;
    }
    .desktop-main-header {
      display: block;
    }
  }
`;

export const Search = styled.div`
  display: none;
  margin: ${(props) =>
    props.theme.direction === "RTL" ? "0 auto 0 0;" : "0 0 0 auto;"};
  position: absolute;
  top: 51px;
  ${(props) =>
    props.theme.direction === "RTL" ? "left: 195px;" : "right: 195px;"}
  width: 400px;
  padding: 0 20px;
  -transition: 0.2s all ease-in-out;
  overflow: hidden;
  svg {
    width: 18px;
    position: absolute;
    ${(props) =>
      props.theme.direction === "RTL" ? "right: 29px" : "left: 29px"};
    opacity: 0.3;
    top: 5px;
    z-index: 9;
  }
  .search-input {
    font-family: "Cairo";
    color: #3a3a46;
    font-size: 15px;
    height: 35px;
    width: calc(100% - 35px);
    padding: 0;
    border: 0;
    outline: none;
    border-radius: 8px;
    padding: ${(props) =>
      props.theme.direction === "RTL" ? "0 35px 0 0" : "0 0 0 35px"};
    background-color: #ffffff;
    transition: all 0.2s ease-in-out;
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
  }
  .cancel {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    outline: none;
    border: 0;
    position: absolute;
    background-color: rgba(219, 216, 213, 0.3);
    right: 30px;
    top: 7.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      top: unset;
      left: unset;
    }
  }
  @media only screen and (min-width: 930px) {
    display: block;
  }
`;
