import React, { FC } from "react";
import styled from "styled-components";
import { URL } from "../api/config";

type TitleType = {
  category?: boolean;
  title: string;
  img?: string | null | undefined;
  onNavigate?: () => void;
  decoration: string;
} & React.HTMLAttributes<HTMLDivElement>;

type WrapperType = {
  category?: boolean;
  img: boolean;
};

const Subtitle: FC<TitleType> = ({
  title,
  category,
  img,
  onNavigate,
  decoration,
  ...restProps
}) => {
  const handleNavigate = () => {
    if (onNavigate) {
      onNavigate();
    }
  };

  return (
    <Wrapper
      id="category"
      category={category}
      img={!!img}
      onClick={handleNavigate}
      {...restProps}
    >
      <img
        src={URL + decoration}
        className="background-style"
        alt="background style"
      />
      <div className="container">
        {img && <img src={URL + img} alt="category" />}
        <div className="title">
          <p>{title}</p>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperType>`
  position: relative;
  margin: auto;
  transition: all 0.15s ease-in-out;
  :hover {
    transform: translate(0px, -5px) scale(1.02);
  }
  .background-style {
    width: ${(props) => (props.category ? "280px" : "240px")};
    position: absolute;
    left: ${(props) =>
      props.category ? "calc(50% - 135px)" : "calc(50% - 120px)"};
    margin: 18px 0;
  }
  .container {
    width: ${(props) => (props.category ? "300px" : "220px")};
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: ${(props) =>
      props.theme.direction && props.theme.direction === "LTR"
        ? "row"
        : "row-reverse"};
    margin: auto;
    img {
      width: 80px;
      height: 80px;
      margin: auto;
      object-fit: contain;
    }
    .title {
      width: fill-available;
      height: ${(props) => (props.category ? "80px" : "70px")};
      display: flex;
      align-items: center;
      margin: ${(props) => (props.category ? "auto auto auto 0px" : "auto")};
      font-size: 20px;
      color: #444444;
      font-family: ${(props) =>
        props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
      font-weight: 500;
      p {
        width: 100%;
        font-size: ${(props) => props.theme.CategoryfontSize}px;
        padding: ${(props) =>
          props.theme.direction === "RTL" ? "0 7px 0 0" : "0 0 0 7px"};
        color: ${(props) => props.theme.paletteColors.accent};
        text-align: ${(props) =>
          // eslint-disable-next-line no-nested-ternary
          props.category
            ? props.theme.direction && props.theme.direction === "LTR"
              ? "left"
              : "right"
            : "center"};
      }
    }
  }
`;

export default Subtitle;
