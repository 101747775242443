import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 100%;
  margin: 200px auto 0;
  .contact-area {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    h1 {
      font-family: Cairo;
      font-size: 46px;
      line-height: 56px;
      color: ${(props) => props.theme.paletteColors.secondary};
    }
    .contact-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 30px;
      input {
        width: calc(100% - 30px);
        height: 45px;
        padding: 0 15px;
        border: 0;
        border-radius: 8px;
        font-family: Cairo;
        font-size: 15px;
      }
      .input__email {
        grid-column: span 2;
      }
      textarea {
        width: calc(100% - 30px);
        grid-column: span 2;
        padding: 15px;
        border: 0;
        border-radius: 8px;
        font-family: Cairo;
        font-size: 15px;
        resize: none;
      }
      .button__send {
        width: 120px;
        height: 45px;
        grid-column: span 2;
        margin: 0 0 0 auto;
        background-color: ${(props) => props.theme.paletteColors.secondary};
        border: 0;
        border-radius: 8px;
        font-size: 16px;
        font-family: Cairo;
        color: ${(props) => props.theme.paletteColors.primary};
      }
    }
  }
  .footer-area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 15px;
    font-family: Cairo;
    color: ${(props) => props.theme.paletteColors.primary};
    h3 {
      margin: 0;
      color: ${(props) => props.theme.paletteColors.secondary};
    }
    h3.__contact-us {
      grid-column: span 2;
    }
    p {
      margin: 0;
    }
    .contact__info {
      display: grid;
      grid-template-columns: 30px auto;
      svg {
        stroke: ${(props) => props.theme.paletteColors.secondary};
        margin: auto 0;
      }
      p {
        margin: auto 0;
      }
    }
    ul {
      display: flex;
      grid-column: span 3;
      margin: 50px auto;
      padding: 0;
      a {
        li {
          margin: 0 15px;
          list-style: none;
          cursor: pointer;
          svg {
            fill: ${(props) => props.theme.paletteColors.secondary};
          }
        }
      }
    }
  }
  div.copyrights {
    width: 100%;
    max-width: 1200px;
    position: fixed;
    bottom: 0;
    left: calc(50vw - 600px);
    margin: 0 auto;
    background-color: #00000075;
    p {
      margin: 8px 0;
      color: white;
      font-family: Cairo, Cambria, Cochin, Georgia, Times, "Times New Roman",
        serif;
      text-align: center;
    }
  }
  @media only screen and(min-width: 930px) {
    div.copyrights {
      left: 0;
    }
  }
`;
